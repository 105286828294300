import { MouseEvent, useEffect, useState } from 'react';
import { config, Flex, Input, Button, useMediaQuery } from '@konta/ui';
import { Search2OutlineIcon, SearchLgLineIcon } from '@konta/icons';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';

function TopNavHelpCenterSearch({ placeholder = 'Buscar' }) {
  const { toggleShowCommandMenu } = useCommandMenuContext();
  const isDesktop = useMediaQuery(config.media.sm);
  const [modKey, setModKey] = useState('⌘');

  useEffect(() => {
    const isMac = /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
    setModKey(isMac ? '⌘' : 'Ctrl');
  }, []);

  return (
    <Flex
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        toggleShowCommandMenu();
      }}
      itemsCenter
      css={{ maxWidth: '155px', cursor: 'pointer' }}
    >
      {isDesktop ? (
        <Input
          size="s"
          readOnly
          name="searchKeyword"
          id="searchKeyword"
          placeholder={placeholder}
          leftIcon={<Search2OutlineIcon />}
          rightIcon={
            <Flex cmdk-shortcuts="">
              <kbd className="kbd-top-width">{modKey} k</kbd>
            </Flex>
          }
        />
      ) : (
        <Button noFill variant="outlined" icon size="xs" color="gray">
          <SearchLgLineIcon />
        </Button>
      )}
    </Flex>
  );
}
export default TopNavHelpCenterSearch;
