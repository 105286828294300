import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useAnnualsDeclarationQuery from '@hooks/useAnnualsDeclarationQuery';
import useWorkflows from 'shared/hooks/useWorkflows';
import useTaxableEntity from '@hooks/useTaxableEntity';
import { AnnualDeclaration } from 'types/entities';
import usePreferredFiscalRegime from 'shared/hooks/usePreferredFiscalRegime';
import { useToggle } from 'rooks';

const views = {
  annualDeclaration: 'annual-declaration',
  entries: 'entries',
};

interface StateParams {
  filters: {
    month: string;
    type: 'income' | 'expense';
  };
}

export default function useAnnualDeclarations() {
  const [view, setView] = useSearchParamsState('view', views.annualDeclaration);
  const [openPersonalDeductionModal, toggleOpenPersonalDeductionModal] =
    useToggle();
  const [filters, setFilters] = useState<Record<string, string>>({
    month: '',
    type: '',
  });

  const [period, setPeriod] = useState(() => {
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    const urlPeriod = params.get('period');
    if (urlPeriod) {
      return +urlPeriod;
    }
    const currentMonth = dayjs().month();
    // If we're in the first quarter of the year (Jan-Apr), we default to previous year
    // since users typically work on last year's annual declaration during these months
    if ([0, 1, 2, 3].includes(currentMonth)) {
      return dayjs().subtract(1, 'year').year();
    }
    return dayjs().year();
  });

  const {
    loadingDeclaration: loadingAnnualDeclarations,
    declarations: annualDeclarations,
  } = useAnnualsDeclarationQuery();

  const { preferredFiscalRegime, loadingPreferredFiscalRegime } =
    usePreferredFiscalRegime();

  const { workflows, workflowsLoading } = useWorkflows({
    params: {
      'q[active_true]': 1,
      'q[start_date_gteq]': `${period}-01-01`,
      'q[start_date_lteq]': `${period}-12-31`,
    },
  });

  const declarations = useMemo(
    () =>
      workflows.flatMap((workflow) =>
        workflow.declarations.filter((declaration) => declaration.is_active),
      ),
    [workflows],
  );

  const { taxable_entity } = useTaxableEntity();

  const { state } = useLocation();

  useEffect(() => {
    const savedState = state as StateParams;
    if (savedState?.filters) {
      setFilters(savedState.filters);
    }
  }, [state]);

  const currentAnnualDeclarations = useMemo(
    () =>
      annualDeclarations?.length
        ? annualDeclarations
            .filter((annual) => annual.period === +period)
            .map((annualDeclaration) => {
              const { status, annual_declaration_totals } = annualDeclaration;
              const createdBy = status === 'open' ? 'konta_app' : 'retool_app';

              return {
                ...annualDeclaration,
                annual_declaration_totals: annual_declaration_totals?.filter(
                  (_declaration) => _declaration.created_by === createdBy,
                ),
              } as AnnualDeclaration;
            })
        : [],
    [annualDeclarations, period],
  );

  const resumeByRegimeBreadcrumbs = [
    {
      label: 'Declaración anual',
      onClick: () => setView(views.annualDeclaration),
      isActive: view === views.annualDeclaration,
    },
  ];
  const entriesBreadcrumbs = [
    ...resumeByRegimeBreadcrumbs,
    {
      label: 'Detalle de facturación',
      onClick: () => setView(views.entries),
      isActive: view === views.entries,
    },
  ];

  const workflowsId = useMemo(
    () => workflows?.map((workflow) => workflow.id),
    [workflows],
  );

  const onHandleEntriesView = (month: string, type: 'income' | 'expense') => {
    setFilters({
      month,
      type,
    });
    setView(views.entries);
  };

  const isLoading =
    workflowsLoading ||
    loadingAnnualDeclarations ||
    loadingPreferredFiscalRegime;

  return {
    setView,
    filters,
    setFilters,
    resumeByRegimeBreadcrumbs,
    entriesBreadcrumbs,
    onHandleEntriesView,
    isLoading,
    workflows,
    workflowsId,
    taxableEntity: taxable_entity,
    period,
    setPeriod,
    annualDeclarations,
    currentAnnualDeclarations,
    preferredFiscalRegime,
    declarations,
    view,
    views,
    openPersonalDeductionModal,
    toggleOpenPersonalDeductionModal,
  };
}
