import { useMemo, useState } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';
import useCfdisPagination from 'shared/hooks/useCfdisPagination';
import useCfdiStore from 'store/cfdisStore';
import useCommonCfdiColumns from '../useCommonCfdiColumns';
import getRansackFilters from '../getRansackFilters';
import useInvoicesVisisted from '../useInvoicesVisisted';
import { isNewInvoice } from '../utils';

interface UseEseReceivedCfdisProps {
  setCfdi: (cfdi: any) => void;
  setTogglePdf: () => void;
  setToggleComplement: () => void;
  handleShowReports: (original: any) => void;
}

export default function useReceivedCfdis({
  setCfdi,
  setTogglePdf,
  setToggleComplement,
  handleShowReports,
}: UseEseReceivedCfdisProps) {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { lastVisit } = useInvoicesVisisted({ source: 'received' });
  const { selectedTab, dateFilter } = useCfdiStore((state) => ({
    selectedTab: state.selectedTab,
    dateFilter: state.dateFilter,
  }));
  const ransackFilters = useMemo(
    () => getRansackFilters(filters, selectedTab, dateFilter),
    [dateFilter, filters, selectedTab],
  );
  const lastReceivedVisit = lastVisit?.received || null;
  const columns = useCommonCfdiColumns({
    setCfdi,
    setTogglePdf,
    setToggleComplement,
    handleShowReports,
    lastVisit: lastReceivedVisit,
  });

  const { pagination, cfdisLoading, cfdis } = useCfdisPagination({
    apiVersion: 2,
    params: ransackFilters,
  });

  const hasNewInvoices = useMemo(
    () =>
      cfdis.some((cfdi) => isNewInvoice(cfdi.created_at, lastReceivedVisit)),
    [cfdis, lastReceivedVisit],
  );

  return {
    columns,
    pagination,
    cfdis,
    cfdisLoading,
    setFilters,
    hasNewInvoices,
  };
}
