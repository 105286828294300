import { ReactNode } from 'react';
import { Modal as ModalComponent } from 'reactstrap';
import { Button } from '@konta/ui';
import { XCloseLineIcon } from '@konta/icons';
import {
  ActionsWrapper,
  CloseIconWrapper,
  Content,
  Subtitle,
  Title,
  TitleSection,
  HorizontalLine,
  BannerWrapper,
} from './styled';
import bannerImg from './banner-img.png';

interface ModalProps {
  children: ReactNode;
  isLoading?: boolean;
  modalSize?: 'sm' | 'lg' | 'xl';
  isOpen: boolean;
  toggleIsOpen: () => void;
  centered?: boolean;
  modalTitle?: string;
  modalTitleAlign?: 'left' | 'center' | 'right';
  modalSubtitle?: string;
  withBanner?: boolean;
  actions?: ReactNode;
  onCloseButton?: () => void;
  closeTextBtn?: string;
  withTitleBorderBottom?: boolean;
  withFooterBorderTop?: boolean;
}
export default function Modal({
  modalTitle,
  modalSubtitle,
  children,
  isLoading,
  modalSize = 'lg',
  isOpen,
  toggleIsOpen,
  centered = true,
  withBanner = true,
  actions,
  onCloseButton,
  closeTextBtn = 'Cerrar',
  modalTitleAlign = 'center',
  withTitleBorderBottom = false,
  withFooterBorderTop = false,
}: ModalProps) {
  return (
    <ModalComponent
      isOpen={isOpen}
      toggle={toggleIsOpen}
      centered={centered}
      height="full"
      size={modalSize}
      wrapClassName="modal-new-design"
    >
      <CloseIconWrapper onClick={toggleIsOpen}>
        <XCloseLineIcon color="#667085" />
      </CloseIconWrapper>
      {withBanner && (
        <BannerWrapper>
          <img alt="banner-img" src={bannerImg} />
        </BannerWrapper>
      )}
      <Content>
        {modalTitle && (
          <TitleSection>
            <Title align={modalTitleAlign}>{modalTitle}</Title>
            {modalSubtitle && <Subtitle>{modalSubtitle}</Subtitle>}
          </TitleSection>
        )}
        {withTitleBorderBottom && <HorizontalLine />}
        {children}
      </Content>
      {withFooterBorderTop && <HorizontalLine footer />}
      <ActionsWrapper end={!actions}>
        {actions || (
          <Button
            loading={isLoading}
            onClick={onCloseButton || toggleIsOpen}
            variant="outlined"
          >
            {closeTextBtn}
          </Button>
        )}
      </ActionsWrapper>
    </ModalComponent>
  );
}
