import {
  CalculatorLineIcon,
  FileSearch02LineIcon,
  InfoCrFr2OutlineIcon,
  MessageChatSquareLineIcon,
} from '@konta/icons';
import dayjs from 'dayjs';
import {
  Flex,
  styled,
  Text,
  AccordionTable,
  Tabs,
  Tab,
  Button,
} from '@konta/ui';
import { ConfirmModal } from '@components/Modal';
import ActionableMetricCard from 'shared/components/ActionableMetricCard';
import Sidebar from 'shared/components/Sidebar';
import toCurrency from '@util/toCurrency';
import * as chat from '@util/chat';
import { AnnualDeductionCategoryKey, SelectedCategory } from 'types/entities';
import { Dispatch, SetStateAction } from 'react';
import { ProgressBarSection } from 'shared/components/ActionableMetricCard/ActionableMetricCard';
import useDeductionDetails from './useDeductionDetails';
import CfdisModalTable from './CfdisModalTable';

const Header = styled('span', {
  fontSize: '18px',
  fontWeight: '700',
  color: 'black',
  lineHeight: '28px',
});

// Define interface for DeductionDetailsContent props
interface DeductionDetailsContentProps {
  accumulated: number;
  globalLimit: number;
  progressBarSections: ProgressBarSection[];
  availableForDeduction: number;
  categoriesAccordionData: Array<any>;
  mothsLimitsAccordionData: Array<any>;
  tab: 'categories' | 'limits' | undefined;
  setTab: (tab: 'categories' | 'limits') => void;
  isMobile: boolean;
  openModalCfdisTable: boolean;
  toggleOpenModalCfdisTable: () => void;
  selectedPeriodDate: string | null;
  setSelectedPeriodDate: Dispatch<SetStateAction<string | null>>;
  selectedCategory: SelectedCategory | null;
  setSelectedCategory: Dispatch<SetStateAction<SelectedCategory | null>>;
  invoiceModalTitle: string;
  isLimitFull: boolean;
  openModalCategoryDescription: boolean;
  toggleOpenModalCategoryDescription: () => void;
  labelName: Record<AnnualDeductionCategoryKey, string>;
  labelDescription: Record<AnnualDeductionCategoryKey, string>;
  periodDate?: string;
}

// Define interface for DeductionDetails props
interface DeductionDetailsProps {
  withSidebar?: boolean;
  periodDate?: string;
}

// Extracted sidebar content to a separate component for reusability
function DeductionDetailsContent({
  accumulated,
  globalLimit,
  progressBarSections,
  availableForDeduction,
  categoriesAccordionData,
  mothsLimitsAccordionData,
  tab,
  setTab,
  isMobile,
  openModalCfdisTable,
  toggleOpenModalCfdisTable,
  selectedPeriodDate,
  setSelectedPeriodDate,
  selectedCategory,
  setSelectedCategory,
  invoiceModalTitle,
  isLimitFull,
  openModalCategoryDescription,
  toggleOpenModalCategoryDescription,
  labelName,
  labelDescription,
  periodDate,
}: DeductionDetailsContentProps) {
  return (
    <Flex
      gap={16}
      column
      css={{
        width: '100%',
        paddingTop: '16px',
        height: '100%',
      }}
    >
      {openModalCfdisTable && (
        <CfdisModalTable
          isOpen={openModalCfdisTable}
          toggleIsOpen={toggleOpenModalCfdisTable}
          periodDate={periodDate ?? selectedPeriodDate ?? ''}
          setSelectedPeriodDate={setSelectedPeriodDate}
          title={invoiceModalTitle}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {openModalCategoryDescription && (
        <ConfirmModal
          open={openModalCategoryDescription}
          title={
            selectedCategory?.name ||
            labelName[selectedCategory?.category as keyof typeof labelName] ||
            ''
          }
          onClose={toggleOpenModalCategoryDescription}
          centered
          size="lg"
          withoutAcceptBtn
          footerSide="start"
          extraFooterContent={
            <Text
              css={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '18px',
                color: '$gray500',
              }}
            >
              ¿Tienes alguna duda?{' '}
              <Button
                css={{
                  fontWeight: '600',
                  color: '$primary700',
                  svg: {
                    path: {
                      stroke: '$primary700',
                    },
                  },
                }}
                size="xs"
                variant="text"
                onClick={() => {
                  chat.open();
                }}
                noFill
                rightIcon={<MessageChatSquareLineIcon />}
              >
                Hablar con un asesor
              </Button>
            </Text>
          }
        >
          <Flex gap={16} column>
            <Text
              css={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              {
                labelDescription[
                  selectedCategory?.category as keyof typeof labelDescription
                ]
              }
            </Text>
          </Flex>
        </ConfirmModal>
      )}
      <ActionableMetricCard
        title="Deducción acumulada a la fecha"
        amount={accumulated}
        isCurrency
        headingIcon={<CalculatorLineIcon />}
        withProgressBar
        progressSections={progressBarSections}
        progressLimit={globalLimit}
        withColorsProgressContainer
        footerText={`Disponible para deducir: ${toCurrency(
          availableForDeduction,
        )}`}
        isMobile={isMobile}
        status={isLimitFull ? 'error' : null}
        alertText={
          isLimitFull ? 'Has alcanzado el límite total de tus deducciones.' : ''
        }
      />
      <Tabs
        value={tab}
        size={isMobile ? 's' : 'm'}
        variant="switch"
        css={{ overflowX: 'auto' }}
        onChange={(newTab) => {
          setTab(newTab as 'categories' | 'limits');
        }}
        indicator="bottom"
      >
        <Tab id="categories" css={{ flex: 1 }}>
          Categorías
        </Tab>
        <Tab id="limits" css={{ flex: 1 }}>
          Límite de deducción{' '}
        </Tab>
      </Tabs>
      {tab === 'categories' ? (
        <AccordionTable initialData={categoriesAccordionData} />
      ) : (
        <AccordionTable
          initialData={mothsLimitsAccordionData}
          tableFooter={
            <Flex column gap={8}>
              <Text
                css={{
                  color: '$gray700',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '18px',
                }}
              >
                ¿Cómo se calcula el límite de tus deducciones?
              </Text>
              <Text
                css={{
                  color: '$gray500',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '18px',
                }}
              >
                Según lo establecido por el SAT, el monto total de las
                deducciones personales (excepto gastos médicos por incapacidad y
                discapacidad, donativos, aportaciones voluntarias y aportaciones
                complementarias de retiro, así como estímulos fiscales) no puede
                exceder de cinco Unidades de Medida y Actualización (UMA)
                anuales o del 15% total de tus ingresos, incluidos los exentos,
                lo que resulte menor.
              </Text>
            </Flex>
          }
        />
      )}
    </Flex>
  );
}

export default function DeductionDetails({
  withSidebar = true,
  periodDate,
}: DeductionDetailsProps) {
  const {
    accumulated,
    globalLimit,
    progressBarSections,
    availableForDeduction,
    categoriesAccordionData,
    mothsLimitsAccordionData,
    tab,
    setTab,
    isMobile,
    isSidebarOpen,
    toggleIsSidebarOpen,
    openModalCfdisTable,
    toggleOpenModalCfdisTable,
    selectedPeriodDate,
    setSelectedPeriodDate,
    selectedCategory,
    setSelectedCategory,
    updatedValues,
    openConfirmModal,
    toggleOpenConfirmModal,
    isLoading,
    handleUpdateLimits,
    invoiceModalTitle,
    annualDeduction,
    isLimitFull,
    openModalCategoryDescription,
    toggleOpenModalCategoryDescription,
    labelName,
    labelDescription,
  } = useDeductionDetails({
    periodDate,
  });

  if (!annualDeduction) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        loading={isLoading}
        title="Actualizar límites"
        open={openConfirmModal}
        onAccept={handleUpdateLimits}
        onCancel={toggleOpenConfirmModal}
        onClose={toggleOpenConfirmModal}
      >
        Estás actualizando el valor del periodo de{' '}
        {dayjs(updatedValues?.period).format('MMMM')} ¿Deseas continuar?
      </ConfirmModal>

      {withSidebar ? (
        // Render with sidebar when withSidebar is true and sidebar is open
        isSidebarOpen && (
          <Sidebar
            headerTitle="Deducción total"
            headerIcon={<CalculatorLineIcon />}
            isOpen={isSidebarOpen}
            toggleIsOpen={toggleIsSidebarOpen}
            isMobile={isMobile}
          >
            <DeductionDetailsContent
              accumulated={accumulated}
              globalLimit={globalLimit}
              progressBarSections={progressBarSections}
              availableForDeduction={availableForDeduction}
              categoriesAccordionData={categoriesAccordionData}
              mothsLimitsAccordionData={mothsLimitsAccordionData}
              tab={tab}
              setTab={setTab}
              isMobile={isMobile}
              openModalCfdisTable={openModalCfdisTable}
              toggleOpenModalCfdisTable={toggleOpenModalCfdisTable}
              selectedPeriodDate={selectedPeriodDate}
              setSelectedPeriodDate={setSelectedPeriodDate}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              invoiceModalTitle={invoiceModalTitle}
              isLimitFull={isLimitFull}
              openModalCategoryDescription={openModalCategoryDescription}
              toggleOpenModalCategoryDescription={
                toggleOpenModalCategoryDescription
              }
              labelName={labelName}
              labelDescription={labelDescription}
            />
          </Sidebar>
        )
      ) : (
        // Render the content directly when withSidebar is false
        <DeductionDetailsContent
          accumulated={accumulated}
          globalLimit={globalLimit}
          progressBarSections={progressBarSections}
          availableForDeduction={availableForDeduction}
          categoriesAccordionData={categoriesAccordionData}
          mothsLimitsAccordionData={mothsLimitsAccordionData}
          tab={tab}
          setTab={setTab}
          isMobile={isMobile}
          openModalCfdisTable={openModalCfdisTable}
          toggleOpenModalCfdisTable={toggleOpenModalCfdisTable}
          selectedPeriodDate={selectedPeriodDate}
          setSelectedPeriodDate={setSelectedPeriodDate}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          invoiceModalTitle={invoiceModalTitle}
          isLimitFull={isLimitFull}
          openModalCategoryDescription={openModalCategoryDescription}
          toggleOpenModalCategoryDescription={
            toggleOpenModalCategoryDescription
          }
          labelName={labelName}
          labelDescription={labelDescription}
          periodDate={periodDate}
        />
      )}

      <Flex
        gap={16}
        column
        css={{
          width: '100%',
          flex: 1,
        }}
      >
        {withSidebar && (
          <>
            <Header>Conoce el detalle de tus deducciones 💸</Header>
            <ActionableMetricCard
              title="Deducción Total"
              amount={accumulated}
              isCurrency
              withProgressBar
              progressSections={progressBarSections}
              progressLimit={globalLimit}
              withRightValueButton
              intercomTarget="deduction-limit-detail"
              isMobile={isMobile}
              actionHelpText={
                <Text
                  css={{
                    color: '$gray700',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                  }}
                >
                  Disponible para deducir:{' '}
                  <Text
                    css={{
                      fontWeight: '600',
                    }}
                  >
                    {toCurrency(availableForDeduction)}
                  </Text>
                </Text>
              }
              rightValueButtonProps={{
                children: isMobile ? <FileSearch02LineIcon /> : 'Ver detalle',
                onClick: () => {
                  setTab('limits');
                  if (withSidebar) {
                    toggleIsSidebarOpen();
                  }
                },
                icon: isMobile,
                rightIcon: !isMobile && <FileSearch02LineIcon />,
              }}
              withLeftValueButton
              leftValueButtonProps={{
                children: <InfoCrFr2OutlineIcon />,
                icon: true,
                variant: 'outlined',
                onClick: () => {
                  setTab('categories');
                  if (withSidebar) {
                    toggleIsSidebarOpen();
                  }
                },
              }}
              status={isLimitFull ? 'error' : null}
              alertText={
                isLimitFull
                  ? 'Has alcanzado el límite total de tus deducciones.'
                  : ''
              }
            />
          </>
        )}
      </Flex>
    </>
  );
}
