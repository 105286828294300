import { useCallback, useMemo, useRef } from 'react';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import getSimplifiedDataGridBySatKeys from 'shared/util/getSimplifiedDataGridBySatKeys';
import type { Range } from 'types/grids';
import useAnnualDeclarationColumns from './useAnnualDeclarationColumns';
import useAnnualDeclarationRows from './useAnnualDeclarationRows';

interface UseGridOpts {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  title: string;
  toggleOpenPersonalDeductionModal: () => void;
}
export default function useGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  title,
  toggleOpenPersonalDeductionModal,
}: UseGridOpts) {
  const columns = useAnnualDeclarationColumns();
  const dataSet = useMemo(
    () =>
      getSimplifiedDataGridBySatKeys({
        satKey: satKeys,
        workflows,
        isForAnnualDeclaration: true,
      }),
    [satKeys, workflows],
  );

  // Add a ref to track if the modal toggle has been called
  const hasToggledModalRef = useRef(false);

  const rows = useAnnualDeclarationRows({
    dataSet,
    selectedMonth: null,
    title,
    period,
    selectedAnnualDeclaration,
    workflows,
  });

  const onSelectionChanged = useCallback(
    (range: Range[]) => {
      if (!range || range.length === 0 || !range[0].first) {
        return;
      }
      const {
        first: { column, row },
      } = range[0];
      if (!column || !row) {
        return;
      }

      const monthIdx = column.idx - 1;
      const isExpense = row.idx === 2;
      const isPersonalDeduction = row.idx === 6 && monthIdx === 0;

      if (isPersonalDeduction && !hasToggledModalRef.current) {
        hasToggledModalRef.current = true;
        // requestAnimationFrame to ensure we're not in the middle of a React render cycle
        requestAnimationFrame(() => {
          toggleOpenPersonalDeductionModal();

          // Reset the ref after a short delay
          setTimeout(() => {
            hasToggledModalRef.current = false;
          }, 300);
        });
        return;
      }

      if (monthIdx < 1 || monthIdx > 12 || row.idx < 1 || row.idx > 2) {
        return;
      }

      onHandleEntriesView(`${monthIdx}`, isExpense ? 'expense' : 'income');
    },
    [onHandleEntriesView, toggleOpenPersonalDeductionModal],
  );

  return {
    columns,
    rows,
    onSelectionChanged,
  };
}
