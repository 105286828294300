import { Flex, Text } from '@konta/ui';

export default function NewInvoicesText() {
  return (
    <Flex gap={8} itemsCenter>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
      >
        <circle cx="4" cy="4" r="4" fill="#039855" />
      </svg>
      <Text color="gray700" bold>
        Tienes facturas nuevas
      </Text>
    </Flex>
  );
}
