import { ChangeEvent } from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { Alert, CircularLoading, Flex, Spacer, Text } from '@konta/ui';
import FormikTextInput from 'shared/components/FormikTextInput';

interface UploadCiecFormValues {
  rfc: string;
  ciec: string;
}

interface UploadCiecFormProps {
  formik: FormikProps<UploadCiecFormValues>;
  isCIECInvalid: boolean;
  isCIECPending: boolean;
  enabledForm: boolean;
  isLoading: boolean;
}

export default function UploadCiecForm({
  formik,
  isCIECInvalid,
  isCIECPending,
  enabledForm,
  isLoading,
}: UploadCiecFormProps) {
  const alertCss = { maxWidth: '670px', flex: 1 };

  return (
    <FormikProvider value={formik}>
      <Flex direction="column" gap={24}>
        <Text>
          Konta necesita tus credenciales del SAT para sincronizar toda tu
          información de manera segura. Este proceso solo tomará unos minutos, y
          una vez completado, podrás consultar el estado actual de tus
          obligaciones fiscales directamente en el Visor Fiscal.
        </Text>
        <FormikTextInput
          name="rfc"
          data-cy="uploadCiecRfcInput"
          placeholder="XAXX010101000"
          label="RFC"
          disabled={!enabledForm}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue('rfc', e.target.value.toUpperCase())
          }
        />
        <FormikTextInput
          label="Contraseña SAT (CIEC)"
          type="password"
          name="ciec"
          data-cy="uploadCiecCiecInput"
          placeholder="*****"
          disabled={!enabledForm}
        />
      </Flex>

      {(isLoading || isCIECPending) && (
        <Flex css={{ marginTop: '24px' }}>
          <Alert color="primary" css={alertCss}>
            La verificación de la CIEC está en proceso <CircularLoading s />
            <Spacer />
            Este proceso puede tomar aproximadamente 2 minutos, por favor no
            cierre el navegador.
          </Alert>
        </Flex>
      )}

      {isCIECInvalid && (
        <Flex css={{ marginTop: '24px' }}>
          <Alert color="error" css={alertCss}>
            La CIEC ó RFC ingresados no son validos.
            <Spacer />
            Por favor ingresalos nuevamente.
          </Alert>
        </Flex>
      )}
    </FormikProvider>
  );
}
