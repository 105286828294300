import { DataTable } from '@konta/ui';
import useReceivedCfdis from './useReceivedCfdis';
import { rowStyle } from '../styled';
import NewInvoicesText from '../NewinvoicesText';

interface EmittedInvoicesProps {
  setCfdi: (cfdi: any) => void;
  setTogglePdf: () => void;
  setToggleComplement: () => void;
  handleShowReports: (original: any) => void;
}

export default function ReceivedCfdis({
  setCfdi,
  setTogglePdf,
  setToggleComplement,
  handleShowReports,
}: EmittedInvoicesProps) {
  const {
    columns,
    pagination,
    cfdis,
    cfdisLoading,
    setFilters,
    hasNewInvoices,
  } = useReceivedCfdis({
    setCfdi,
    setTogglePdf,
    setToggleComplement,
    handleShowReports,
  });
  return (
    <DataTable
      initialColumnPinning={{
        right: ['actionsBtn'],
      }}
      filterControlLeftActions={hasNewInvoices && <NewInvoicesText />}
      manualPaginationProps={pagination}
      manualPagination
      data={cfdis}
      columns={columns}
      loading={cfdisLoading}
      noDataLabel="No hay facturas recibidas"
      dataTableId="receivedInvoicesV2"
      onFiltersChange={setFilters}
      withGroupingControl
      tBodyCss={rowStyle}
    />
  );
}
