import { Icon, Text } from '@konta/ui';
import * as chat from '@util/chat';

import { ArrowCircleRightLineIcon } from '@konta/icons';
import { ReactComponent as Img } from './imagen.svg';

import {
  CardContainer,
  CardTitle,
  CardBody,
  CardBodyItem,
  CardBodyText,
  CardFooterText,
  ImageContainer,
} from './styled';

export default function MeantimeAnnualBanner() {
  return (
    <CardContainer>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <CardBody>
        <CardTitle>Próximos pasos:</CardTitle>
        <CardBodyItem>
          <Icon notFill>
            <ArrowCircleRightLineIcon />
          </Icon>
          <CardBodyText>
            Una vez finalizado nuestro proceso de validación y obtención de tus
            cálculos, nos pondremos en contacto contigo para brindarte todos los
            detalles y actualizaciones sobre tu declaración.
          </CardBodyText>
        </CardBodyItem>
        <CardBodyItem>
          <Icon notFill>
            <ArrowCircleRightLineIcon />
          </Icon>
          <CardBodyText>
            Te notificaremos a través de <Text bold>WhatsApp</Text> y{' '}
            <Text bold>correo electrónico</Text>. Podrás acceder a todos los
            detalles de tu cálculo para su revisión y aprobación.
          </CardBodyText>
        </CardBodyItem>
        <CardFooterText>
          Si tienes alguna pregunta o inquietud, no dudes en{' '}
          <Text
            color="primary700"
            css={{ cursor: 'pointer' }}
            onClick={() => {
              chat.open();
            }}
          >
            contactarnos.
          </Text>
        </CardFooterText>
      </CardBody>
    </CardContainer>
  );
}
