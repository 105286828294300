/* eslint-disable react/no-unstable-nested-components */
import Skeleton from 'react-loading-skeleton';
import { HelpCircleLineIcon, CreditCard02LineIcon } from '@konta/icons';
import { DataTable, Flex, HoverCard, Icon, Text } from '@konta/ui';
import { Cfdi } from 'types/entities';
import dayjs from 'dayjs';
import { PDFModal } from '@components/Utils';
import toCurrency from '@util/toCurrency';
import Collapse from '../CollapseSection/Collapse';
import useCfdisCollapseTable from './useCfdisCollapseTable';

const textSize = {
  '@initial': 'x2s',
  '@md': 'm',
} as const;

export interface CfdisCollapseTableProps {
  period: string;
  invoiceType: 'incomes' | 'expenses';
  index: number;
  cfdis: Cfdi[];
  loader: boolean;
}
export default function CfdisCollapseTable({
  period,
  invoiceType,
  index,
  cfdis,
  loader,
}: CfdisCollapseTableProps) {
  const {
    cfdiModalObject,
    defaultColumns,
    setTogglePdf,
    showEntriesTable,
    togglePdf,
    toggleShowEntriesTable,
    totals,
    setItemHovered,
  } = useCfdisCollapseTable({
    invoiceType,
    cfdis,
  });

  return (
    <>
      <Collapse
        btnLeftIcon={<CreditCard02LineIcon color="#667085" />}
        btnText={
          <Text color="gray500" size={textSize}>
            {dayjs(period).format('MMMM YYYY').toLocaleUpperCase()}
          </Text>
        }
        controls={[showEntriesTable, toggleShowEntriesTable]}
        rightTextCss={{
          gap: 3,
          '@md': {
            gap: 6,
          },
        }}
        rightText={
          loader ? (
            <Skeleton width={200} />
          ) : (
            <>
              <Text size={textSize}>
                {cfdis?.length}{' '}
                {cfdis.length === 1 ? 'transacción' : 'transacciones'}
              </Text>
              <Text size={textSize}>|</Text>
              <Flex align="center" justify="center" gap={6}>
                <Text size={textSize}>
                  Total:{' '}
                  <Text color="primary700">{toCurrency(totals || 0)}</Text>
                </Text>
                <HoverCard
                  side="top"
                  openDelay={0}
                  triggerElement={
                    <Icon
                      tabIndex={-1}
                      role="button"
                      css={{
                        svg: {
                          path: {
                            fill: 'none',
                            stroke: '$gray500',
                          },
                        },
                      }}
                    >
                      <HelpCircleLineIcon />
                    </Icon>
                  }
                >
                  No se contemplan los CFDI de tipo PPD
                </HoverCard>
              </Flex>
            </>
          )
        }
      >
        <Flex column css={{ width: '100%' }}>
          <DataTable
            data={cfdis || []}
            // @ts-ignore TODO: fix typing with react-table
            columns={defaultColumns}
            withHover
            loading={loader}
            dataTableId={`cfdiCollapseTable-${index + 1}`}
            withoutExportControl
            withoutHideControl
            onItemHover={setItemHovered}
          />
        </Flex>
      </Collapse>
      <PDFModal
        url={cfdiModalObject.pdf_url}
        title="Vista previa de la factura"
        id={cfdiModalObject.id}
        status={cfdiModalObject.status}
        onToggle={setTogglePdf}
        togglePdf={togglePdf}
        invoice={cfdiModalObject}
        uuid={undefined}
      />
    </>
  );
}
