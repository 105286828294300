import { useRef, useEffect } from 'react';
import useTaxableEntity from '@hooks/useTaxableEntity';
import dayjs from 'dayjs';
import {
  LastVisitsStorage,
  setStoredLastVisits,
  getStoredLastVisits,
} from './utils';

interface UseInvoicesVisistedProps {
  source: 'emitted' | 'received';
}

export default function useInvoicesVisisted({
  source,
}: UseInvoicesVisistedProps) {
  const { taxable_entity } = useTaxableEntity();
  const taxableEntityId = taxable_entity?.id || 0;
  const lastVisitsRef = useRef<LastVisitsStorage>(getStoredLastVisits());
  const lastVisit = lastVisitsRef.current?.[taxableEntityId] || null;

  useEffect(() => {
    if (!taxableEntityId) return;

    const newLastVisits: LastVisitsStorage = {
      ...(lastVisitsRef.current || {}),
      [taxableEntityId]: {
        ...(lastVisitsRef.current?.[taxableEntityId] || {}),
        [source]: dayjs().format(),
      },
    };

    setStoredLastVisits(newLastVisits);
  }, [taxableEntityId, source]);

  return {
    lastVisit,
  };
}
