import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { Button, ModalContent, ModalHeader, ModalActions } from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';

export default function ConfirmModal({
  acceptText,
  cancelText,
  children,
  onAccept,
  onCancel,
  onClose,
  open,
  title,
  loading,
  withDisabledLoading,
  size = 'md',
  withBlur = false,
  centered = false,
  withFooter = true,
  isAcceptBtnEnabled = true,
  spaceBetweenFooter = false,
  withoutAcceptBtn = false,
  extraFooterContent,
  footerSide = 'end',
  withoutHeader = false,
  withBorderInFooter = true,
}) {
  return (
    <Modal
      centered={centered}
      size={size}
      isOpen={open}
      toggle={onClose}
      {...(withBlur && {
        wrapClassName: 'modal-backdrop-blur',
      })}
    >
      {!withoutHeader && (
        <ModalHeader
          divided
          css={{
            fontWeight: '500',
            fontSize: '18px',
            color: '$gray900',
            mr: '0!important',
            justifyContent: 'space-between',
          }}
        >
          {title}
          <Button icon variant="text" onClick={onClose}>
            <CloseIcon />
          </Button>
        </ModalHeader>
      )}
      <ModalContent css={{ pt: '$24' }}>{children}</ModalContent>
      {withFooter && (
        <ModalActions
          css={{
            p: '$16 $18',
            borderTop: withBorderInFooter ? '1px solid $gray200' : 'none',
          }}
          className={`d-flex ${
            spaceBetweenFooter
              ? 'justify-content-between'
              : `justify-content-${footerSide}`
          }`}
        >
          {!!extraFooterContent && extraFooterContent}
          {!!cancelText && !!onCancel && (
            <Button
              loading={loading}
              variant="outlined"
              data-testid="cancel"
              onClick={onCancel}
              {...(withDisabledLoading && { disabled: loading })}
            >
              {cancelText}
            </Button>
          )}
          {!withoutAcceptBtn && (
            <Button
              loading={loading}
              color="primary"
              data-testid="accept"
              onClick={onAccept}
              disabled={!isAcceptBtnEnabled}
              {...(withDisabledLoading && { disabled: loading })}
            >
              {acceptText}
            </Button>
          )}
        </ModalActions>
      )}
    </Modal>
  );
}

ConfirmModal.propTypes = {
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  withDisabledLoading: PropTypes.bool,
  extraFooterContent: PropTypes.node,
  withoutAcceptBtn: PropTypes.bool,
  footerSide: PropTypes.string,
};

ConfirmModal.defaultProps = {
  acceptText: 'Aceptar',
  cancelText: 'Cancelar',
  children: null,
  onAccept: () => {},
  onCancel: null,
  onClose: () => {},
  open: false,
  title: 'Confirmación',
  loading: false,
  withDisabledLoading: false,
  extraFooterContent: null,
  withoutAcceptBtn: false,
  footerSide: 'end',
};
