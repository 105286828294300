import React from 'react';
import dayjs from 'dayjs';
import { Row } from 'reactstrap';
import { PLAN_NAMES, EXTRA_SERVICE_NAMES } from '@constants/subscription';
import { Colxx as Col } from '@components/CustomBootstrap';
import IntlMessages from '@util/IntlMessages';

const productTypeDict = {
  Declaration: 'Declaración Anual',
  TaxArrear: (arrearMonths) => {
    if (arrearMonths.length === 1) {
      return 'Declaración Atrasada';
    }
    return `Declaración Atrasada (${arrearMonths.length} meses)`;
  },
  Complementary: 'Complementaria',
};

export function OrderServicesSubscriptionDetails({ services }) {
  if (services) {
    const PRODUCT_NAMES = {
      ...PLAN_NAMES,
      ...EXTRA_SERVICE_NAMES,
    };

    return (
      <div>
        <Row
          className="font-weight-bold text-primary mt-2"
          style={{ fontSize: '1rem' }}
        >
          <Col xxs={{ size: 'auto', offset: 0 }}>
            <IntlMessages id="settings.plan.summary" />
          </Col>
        </Row>
        {services.map((service, idx) => (
          <Row key={`subscription-${idx}`}>
            <Col xxs={{ size: 7, offset: 1 }}>
              {PRODUCT_NAMES[service?.service.name]} (Mensual) x{' '}
              {service.quantity}
            </Col>
            <Col xxs={{ size: 3 }} align="right">
              ${service.total}
            </Col>
          </Row>
        ))}
      </div>
    );
  }
  return null;
}

export function OrderServicesTADetails({ services }) {
  if (services) {
    return (
      <div>
        <Row
          className="font-weight-bold text-primary mt-2"
          style={{ fontSize: '1rem' }}
        >
          <Col xxs={{ size: 'auto', offset: 0 }}>
            <IntlMessages id="settings.plan.summary" />
          </Col>
        </Row>
        {services.map((service, idx) => {
          const arrearMonths = service?.arrear_months || [];
          const humanizedArrearMonths = arrearMonths
            .map((month) => dayjs(month).format('MMMM YYYY'))
            .join(', ');
          return (
            <Row key={`ta-${idx}`} className="mb-2">
              <Col xxs={{ size: 7, offset: 1 }}>
                <b>
                  {service?.product_type &&
                  typeof productTypeDict[service.product_type] === 'function'
                    ? productTypeDict[service.product_type](arrearMonths)
                    : service?.product_type
                    ? productTypeDict[service.product_type] ||
                      service.product_type
                    : 'Servicio'}
                  :
                </b>{' '}
                {humanizedArrearMonths}
              </Col>
              <Col xxs={{ size: 3 }} align="right">
                <b>${service.total}</b>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
  return null;
}
