import dayjs from 'dayjs';

const LAST_VISIT_KEY = 'lastInvoicesVisit';

export type LastVisitsStorage = {
  [key: string]: {
    emitted: string;
    received: string;
  };
} | null;

export const getStoredLastVisits = (): LastVisitsStorage => {
  try {
    return JSON.parse(
      localStorage.getItem(LAST_VISIT_KEY) || 'null',
    ) as LastVisitsStorage;
  } catch (error) {
    console.error('Error getting last visit time:', error);
    return null;
  }
};
export const setStoredLastVisits = (lastVisits: LastVisitsStorage) => {
  try {
    localStorage.setItem(LAST_VISIT_KEY, JSON.stringify(lastVisits));
  } catch (error) {
    console.error('Error saving last visit time:', error);
  }
};

export const isNewInvoice = (
  createdAt: string,
  lastVisit?: string | null,
): boolean => {
  if (!lastVisit || !createdAt) return false;
  return dayjs(createdAt).isAfter(dayjs(lastVisit));
};
