export const CLIENT_RFC_VALIDATION = 'clientRfcValidation';
export const BELVO_ACCESS_TOKEN = 'belvoAccessToken';
export const CALENDLY_MODAL = 'calendlyModal';
export const GOOGLE_LOGIN_ENABLED = 'googleLoginEnabled';
export const COMPLEMENT_GLOBAL_ATTRIBUTES_ENABLED =
  'complementGlobalAttributesEnabled';
export const PUBLIC_INVOICE = 'publicInvoice';
export const CHECKOUT_NEXT_PAYMENT_DATE = 'checkoutNextPaymentDate';
export const NOTIFICATION_CENTER = 'notificationCenter';
export const WORKFLOWS_WITH_NO_FIEL = 'workflowsWithNoFiel';
export const REPORTS_PAGE = 'reportsPage';
export const NEW_CLIENT_SUPPLIER_INPUTS = 'newClientSupplierInputs';
export const INVOICE_TEMPLATE = 'invoiceTemplate';
export const WORKFLOW_MODAL = 'workflowModal';
export const MODAL_INSIGHTS = 'modalInsights';
export const NEW_PENDINGS_VIEW = 'newPendingsView';
export const TICKETS_CHAT = 'ticketsChat';
export const COMMAND_MENU = 'commandMenu';
export const UNSUBSCRIBE_REQUEST = 'unsubscribeRequest';
export const NEW_INCOMES_BY_REGIME_GRID = 'newIncomesByRegimeGrid';
export const PAYMENT_IN_INSTALLMENTS_FOR_PREFERENCES_STEP =
  'paymentInInstallmentsForPreferencesStep';
export const ENABLE_GOOGLE_CAPTCHA = 'enableGoogleCaptcha';
export const FISCAL_VISOR = 'fiscalVisor';
export const SAT_BALANCES_2025 = 'satBalances2025';
