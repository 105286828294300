import React, { useMemo } from 'react';
import {
  ExpencesClassification,
  WorkflowIntro,
  EntriesSummary,
  ComputeResult,
  NewNotPlatformIncome,
  CreateForignAndPublicInvoice,
  TaxesPayment,
  PendingDeclaration,
  QuForeignExpences,
  TextExpences,
  CaptureLinePayment,
  WorkflowEnd,
  CreateMisingPublicInvoice,
  ConstancySummary,
  NotCsdUploadInvoice,
  TotalConstancyExceeded,
  ClassifyConstancies,

  // resico
  IntroResico,
  SelectIncomeUploadWay,
  BelvoConnection,
  ClassifyTransaction,
  CreateMisingPublicInvoiceResico,
  HasExemptIva,
  NewHasExtraIncome,
  IncomeReviewRequired,
  NewUploadIncomeTotal,
  UploadTransaction,
  TextExpencesResico,
  QuForeignExpencesResico,
  ExpencesClassificationResico,
  ComputeResultResico,
  NotCsdUploadInvoiceResico,
  CaptureLinePaymentResico,
  ClassifyIncomesResico,
} from '@containers';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Loading from '@components/Utils/Loading';
import { Step, Stepper, Flex, Box } from '@konta/ui';
import { STEPS } from '@constants/stepsByRegimeSatKey';
import InvoicesIncomeVerification from '@containers/Workflows/Phases/resico/InvoicesIncomeVerification';
import AddForeignExpense from '@containers/Workflows/Phases/AddForeignExpence';
import WorkflowsLayout from '@components/WorkflowsLayout';
import { useWorkflows } from '@hooks';

function FlowContainer({ setShowOldWorkflow, showOldWorkflow }) {
  const {
    selected_workflow: { workflow, loading: loadingWorkflow },
    ui,
  } = useWorkflows();

  const {
    current_phase_key: currentPhaseKey,
    active_declaration: activeDeclaration,
  } = workflow;

  const renderWorkflowPhaseByKey = (key) => {
    switch (key) {
      case 'intro':
        return <WorkflowIntro />;
      case 'paperwork':
        return <ClassifyConstancies />;
      case 'classify_incomes_phase':
        return <ClassifyConstancies />;
      case 'constancy_summary':
        return <ConstancySummary />;
      case 'qu_not_platform_income':
        return <NewNotPlatformIncome />;
      case 'create_forign_and_public_invoice':
        return <CreateForignAndPublicInvoice />;
      case 'total_constancy_exceeded':
        return <TotalConstancyExceeded />;
      case 'mising_public_invoice':
        return <CreateMisingPublicInvoice />;
      case 'text_expences':
        return <TextExpences />;
      case 'qu_foreign_expences':
        return <QuForeignExpences />;
      case 'add_foreign_expence':
        return <AddForeignExpense />;
      case 'expences_classification':
        return <ExpencesClassification />;
      case 'entries_summary':
        return <EntriesSummary />;
      case 'compute_result':
        return <ComputeResult />;
      case 'pending_declaration':
        return <PendingDeclaration />;
      case 'capture_line_payment':
        return <CaptureLinePayment />;
      case 'taxes_payment':
        return <TaxesPayment />;
      case 'workflow_end':
        return <WorkflowEnd />;
      case 'not_csd_upload_invoice':
        return <NotCsdUploadInvoice />;

      // resico
      case 'intro_resico':
        return <IntroResico />;
      case 'select_income_upload_way':
        return <SelectIncomeUploadWay />;
      case 'upload_income_total':
        return <NewUploadIncomeTotal />;
      case 'classify_incomes_resico':
        return <ClassifyIncomesResico />;
      case 'belvo_connection':
        return <BelvoConnection />;
      case 'upload_transaction':
        return <UploadTransaction />;
      case 'has_extra_income':
        return <NewHasExtraIncome />;
      case 'has_exempt_iva':
        return <HasExemptIva />;
      case 'classify_transaction':
        return <ClassifyTransaction />;
      case 'create_missing_public_invoice_resico':
        return <CreateMisingPublicInvoiceResico />;
      case 'income_review_required':
        return <IncomeReviewRequired />;
      case 'text_expenses_resico':
        return <TextExpencesResico />;
      case 'invoices_income_verification':
        return <InvoicesIncomeVerification />;
      case 'qu_foreign_expences_resico':
        return <QuForeignExpencesResico />;
      case 'add_foreign_expence_resico':
        return <AddForeignExpense />;
      case 'expences_classification_resico':
        return <ExpencesClassificationResico />;
      case 'compute_result_resico':
        return <ComputeResultResico />;
      case 'not_csd_upload_invoice_resico':
        return <NotCsdUploadInvoiceResico />;
      case 'capture_line_payment_resico':
        return <CaptureLinePaymentResico />;
      default:
        // eslint-disable-next-line no-console
        console.warn('flow:', key);
        return <Loading />;
    }
  };

  const step = useMemo(() => {
    const fiscalRegimeId = activeDeclaration?.fiscal_regime?.sat_key;
    const stepsBySatKey = STEPS[fiscalRegimeId] || STEPS.default;
    return Object.values(stepsBySatKey).findIndex((d) =>
      d.find((p) => p === currentPhaseKey),
    );
  }, [currentPhaseKey, activeDeclaration?.fiscal_regime?.sat_key]);
  const excludePhaseKeysFromStepper = [
    'intro',
    'intro_resico',
    'capture_line_payment_resico',
    'capture_line_payment',
    'taxes_payment',
  ];
  return (
    <WorkflowsLayout
      className="app-container"
      direction="column"
      css={{ backgroundColor: 'white!important' }}
      title="Declaración del mes"
      handleOnClose={setShowOldWorkflow}
      showOldWorkflow={showOldWorkflow}
      isLoading={loadingWorkflow}
    >
      {!excludePhaseKeysFromStepper.includes(currentPhaseKey) && (
        <Flex direction="column" align="center" className="mt-3">
          <Box className="w-100">
            <Stepper value={step} variant="dot">
              <Step id={0} title="1.- Ingresos" />
              <Step id={1} title="2.- Gastos" />
              <Step id={2} title="3.- Calcular impuestos" />
            </Stepper>
          </Box>
        </Flex>
      )}

      <SwitchTransition>
        <CSSTransition
          key={`phase-${currentPhaseKey}`}
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames={`${ui.phase_transition}`}
        >
          {renderWorkflowPhaseByKey(currentPhaseKey)}
        </CSSTransition>
      </SwitchTransition>
    </WorkflowsLayout>
  );

  // This code should be old worklfow, the Feature flag automationPfaeAndPt has been accepted
  /* return (
    <Flex
      className="app-container"
      direction="column"
      css={{ backgroundColor: 'white!important' }}
    >
      {!excludePhaseKeysFromStepper.includes(currentPhaseKey) && (
        <Flex direction="column" align="center" className="mt-3">
          <Box className="w-100">
            <Stepper value={step} variant="tab">
              <Step id={0} title="1.- Ingresos" />
              <Step id={1} title="2.- Gastos" />
              <Step id={2} title="3.- Calcular impuestos" />
            </Stepper>
          </Box>
        </Flex>
      )}

      <SwitchTransition>
        <CSSTransition
          key={`phase-${currentPhaseKey}`}
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames={`${ui.phase_transition}`}
        >
          {renderWorkflowPhaseByKey(currentPhaseKey)}
        </CSSTransition>
      </SwitchTransition>
    </Flex>
  ); */
}

export default FlowContainer;
