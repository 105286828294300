import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row } from 'reactstrap';
import { useToggle } from 'rooks';
import { fetchClientsSuppliers, addressFetch } from '@redux/actions';
import { Colxx } from '@components';
import { Button, ClipboardCopy, Text } from '@konta/ui';
import { ClientsProvidersCard } from '@containers';
import ClientModalForm from '@components/ClientModalForm';
import PostCodeForm from '@components/PostCodeForm';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import { NEW_CLIENT_SUPPLIER_INPUTS } from '@constants/featureFlags';
import useFeature from '@hooks/useFeature';
import useSearchParamsState from '@hooks/useSearchParamsState';

function InvoiceContacts({ view }) {
  const dispatch = useDispatch();
  const [action, setAction] = useSearchParamsState('action', 'list');
  const isClientsOpen = action === 'new';
  const setIsClientsOpen = () => setAction(isClientsOpen ? 'list' : 'new');
  const [isRfcValid, setIsRfcValid] = useState(true);
  const [addressFormOpen, toggleAddressFormOpen] = useToggle(false);
  const [newClientSupplierInputs] = useFeature(NEW_CLIENT_SUPPLIER_INPUTS);

  useEffect(() => {
    dispatch(addressFetch());
  }, [dispatch]);

  const loading = useSelector((state) => state.clientsSuppliers.loading);
  const taxableEntity = useSelector(
    (state) => state.taxableEntity.taxable_entity,
  );
  const clients = useSelector(
    (state) =>
      state.clientsSuppliers.clients_suppliers?.filter?.(
        (item) => item.relationship_type === 'client',
      ) || [],
  );
  const suppliers = useSelector(
    (state) =>
      state.clientsSuppliers.clients_suppliers?.filter?.(
        (item) => item.relationship_type === 'provider',
      ) || [],
  );
  const { address: userHasPostcode, missingPostCode } = useSelector((state) => {
    return {
      address: !!state.addresses.address?.postcode,
      missingPostCode: state.addresses.missingPostCode,
    };
  });

  useEffect(() => {
    if (!missingPostCode) {
      toggleAddressFormOpen();
    }
  }, [toggleAddressFormOpen, missingPostCode]);

  const toggleClients = () => {
    setIsClientsOpen();
    setIsRfcValid(isRfcValid);
  };

  useEffect(() => {
    dispatch(fetchClientsSuppliers(taxableEntity));
  }, [dispatch, taxableEntity]);

  const publicNewClientFormUrl = `${window.location.origin}/new-client/${taxableEntity.uuid}`;

  return (
    <>
      <Row>
        <Colxx xxs="12">
          {!missingPostCode && (
            <Alert color="danger" className="rounded-alert">
              <Text>
                Debido a la nueva facturación 4.0 debes actualizar tu
                información para poder seguir facturando.
              </Text>
              <Text
                underline
                bold
                css={{ cursor: 'pointer', ml: '3px' }}
                onClick={toggleAddressFormOpen}
              >
                Actualizar información aquí.
              </Text>
            </Alert>
          )}
        </Colxx>
      </Row>
      {view === 'clients' && (
        <Row className="mb-4">
          <Colxx md="12" className="d-flex">
            <Button
              color="primary"
              onClick={toggleClients}
              disabled={!userHasPostcode}
              className="mr-2"
            >
              Nuevo Cliente
            </Button>
            {newClientSupplierInputs && (
              <ClipboardCopy
                size="xs"
                variant="outlined"
                leftIcon={
                  <ContentCopyIcon style={{ width: '14px', height: '14px' }} />
                }
                clipboard={publicNewClientFormUrl}
              >
                Copiar link de portal de nuevo cliente
              </ClipboardCopy>
            )}
          </Colxx>
        </Row>
      )}
      <Row className="mb-4">
        <Colxx xxs="12">
          <ClientsProvidersCard
            clients={clients}
            suppliers={suppliers}
            loading={loading}
            taxable_entity={taxableEntity}
            view={view}
          />
        </Colxx>
      </Row>
      {view === 'clients' && (
        <ClientModalForm
          relationship="client"
          isOpen={isClientsOpen}
          toggle={toggleClients}
          onSubmit={toggleClients}
        />
      )}
      <PostCodeForm open={addressFormOpen} onClose={toggleAddressFormOpen} />
    </>
  );
}

export default InvoiceContacts;
