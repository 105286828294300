import dayjs from 'dayjs';

// Define a type for the payment row to match MetricsTotalCard expectations
export interface PaymentRow {
  key: string;
  label: string;
  amount: string | number;
  isCurrency?: boolean;
  isBold?: boolean;
}

interface CalculateDifferredPaymentRowsParams {
  creditBalance: number;
  period?: string | number;
  selectedInstallments: number;
}

/**
 * Get the last business day of a given month and year
 * This excludes weekends (Saturday and Sunday)
 * @param month Month (1-12)
 * @param year Year (e.g. 2024)
 * @returns Date string formatted as DD/MM/YYYY
 */
const getLastBusinessDayOfMonth = (month: number, year: number): string => {
  // Get the last day of the month
  const lastDay = dayjs()
    .year(year)
    .month(month - 1)
    .endOf('month');

  // Check if the last day is a weekend (0 = Sunday, 6 = Saturday)
  const dayOfWeek = lastDay.day();

  // If it's a weekend, move to the previous Friday
  let lastBusinessDay = lastDay;
  if (dayOfWeek === 0) {
    // Sunday
    lastBusinessDay = lastDay.subtract(2, 'day');
  } else if (dayOfWeek === 6) {
    // Saturday
    lastBusinessDay = lastDay.subtract(1, 'day');
  }

  // Format as DD/MM/YYYY
  return lastBusinessDay.format('DD/MM/YYYY');
};

/**
 * Calculates the payment rows for deferred payments based on the Excel formula.
 * @param params The parameters needed for the calculation
 * @returns Array of payment rows
 */
export const calculateDifferredPaymentRows = ({
  creditBalance,
  period,
  selectedInstallments,
}: CalculateDifferredPaymentRowsParams): PaymentRow[] => {
  // El balance total a pagar
  const cleanAmount = Math.abs(creditBalance);

  // The payment period is the year after the declaration period
  const paymentYear = period ? Number(period) + 1 : dayjs().year() + 1;

  // Payment factors based on the Excel spreadsheet
  const factors = {
    2: 0.9875,
    3: 1.9628,
    4: 2.9259,
    5: 3.8771,
    6: 4.8164,
  };

  const factor =
    factors[selectedInstallments as keyof typeof factors] || factors[6];

  // Create payment rows
  const rows: PaymentRow[] = [
    {
      key: 'title',
      label: 'Fecha límite de pago',
      amount: 'Valor a pagar',
      isBold: true,
    },
  ];

  // Calculate the installment amounts dynamically based on the Excel formula

  // Step 1: Calculate first payment (total / installments) without interest
  const basePayment = cleanAmount / selectedInstallments;
  const firstPayment = Math.round(basePayment * 100) / 100;

  // Step 2: Add first payment row (always base amount without interest)
  // First payment is due on the last business day of May (month 5)
  rows.push({
    key: '1',
    label: getLastBusinessDayOfMonth(5, paymentYear),
    amount: firstPayment,
    isCurrency: true,
  });

  // Step 3: Calculate subsequent payments using the Excel formula (Total - FirstPayment) / Factor
  const remainingAmount = cleanAmount - firstPayment;

  // Using the exact Excel formula: (Total amount - First payment) / Factor
  const subsequentPayment = remainingAmount / factor;
  const roundedSubsequentPayment = Math.round(subsequentPayment * 100) / 100;

  let totalPaid = firstPayment;

  // Generate payment rows for installments 2 to n
  const remainingInstallments = Array.from(
    { length: selectedInstallments - 1 },
    (_, i) => i + 2,
  );

  // Using forEach instead of a for loop as requested
  remainingInstallments.forEach((installmentNumber, index) => {
    // All subsequent payments are the same amount
    totalPaid += roundedSubsequentPayment;

    // Calculate the payment month (starting from May + installment number)
    const paymentMonth = 5 + index + 1; // May = 5, June = 6, etc.

    rows.push({
      key: String(installmentNumber),
      label: getLastBusinessDayOfMonth(paymentMonth, paymentYear),
      amount: roundedSubsequentPayment,
      isCurrency: true,
    });
  });

  // Add total row
  rows.push({
    key: 'total',
    label: 'Total a pagar (con interés)',
    amount: Math.round(totalPaid * 100) / 100,
    isCurrency: true,
    isBold: true,
  });

  return rows;
};
