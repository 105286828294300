import { memo, useState } from 'react';
import { ParentSize } from '@visx/responsive';
import { Button, Flex, Text } from '@konta/ui';
import { Document, Page } from 'react-pdf';
import * as chat from '@util/chat';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import Loader from '../Loader';

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

type PDFFile = string | File | null;

function PreviewDocument({
  defaultFile,
  loading = false,
  maxWidth = 600,
  withShadow = false,
  withIframaAsFallback = false,
}: {
  defaultFile: PDFFile;
  loading?: boolean;
  maxWidth?: number;
  withShadow?: boolean;
  withIframaAsFallback?: boolean;
}) {
  const [numPages, setNumPages] = useState<number>();
  const [error, setError] = useState<string | null>(null);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const showIframe =
    !!error && withIframaAsFallback && typeof defaultFile === 'string';

  return (
    <Flex
      column
      align="center"
      css={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        boxShadow: withShadow ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <ParentSize debounceTime={400}>
          {({ width: containerWidth }) =>
            showIframe ? (
              <iframe
                title="PDF"
                src={defaultFile}
                width={containerWidth}
                height={800}
                style={{ border: 'none' }}
              />
            ) : (
              <Document
                file={defaultFile}
                /* @ts-ignore ignore onLoad */
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                loading={<Loader />}
                onLoadError={(e) => {
                  setError(e?.message || 'No se pudo cargar el PDF');
                }}
                noData={
                  <Flex column gap={16}>
                    <Text>No se pudo cargar el PDF</Text>
                    <Button variant="outlined" onClick={chat.open}>
                      Hablar con soporte
                    </Button>
                  </Flex>
                }
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={
                      containerWidth
                        ? Math.min(containerWidth, maxWidth)
                        : maxWidth
                    }
                  />
                ))}
              </Document>
            )
          }
        </ParentSize>
      )}
    </Flex>
  );
}
export default memo(PreviewDocument);
