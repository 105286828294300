import type { Declaration, Workflow } from 'types/entities';
import camelcaseKeys from 'camelcase-keys';
import { getInFavorOrAgainst } from './getDeclarationTotalCalculations';
import calculateAmountsByTransactionType from './calculateSumsOfTransactionType';

// List of workflow statuses that require recalculating
const recalculationStatuses = [
  'submit_declaration',
  'pending_payment',
  'paid',
  'done',
];

// Tax types used in the application
type TaxType = 'iva' | 'isr';

// Mapping of keys based on tax type for general use
const taxKeysByType: Record<
  TaxType,
  {
    totalKey: 'ivaTotal' | 'isrTotal';
    favorKey: 'ivaInFavor' | 'isrInFavor';
    unduePaymentKey: 'unduePaymentIvaInFavor' | 'unduePaymentIsrInFavor';
  }
> = {
  iva: {
    totalKey: 'ivaTotal',
    favorKey: 'ivaInFavor',
    unduePaymentKey: 'unduePaymentIvaInFavor',
  },
  isr: {
    totalKey: 'isrTotal',
    favorKey: 'isrInFavor',
    unduePaymentKey: 'unduePaymentIsrInFavor',
  },
};

// Mapping of keys based on tax type for transaction-specific calculations
const transactionKeysByType: Record<
  TaxType,
  {
    taxKey: 'iva' | 'isr';
    unduePaymentKey: 'undue_payment_iva' | 'undue_payment_isr';
  }
> = {
  iva: {
    taxKey: 'iva',
    unduePaymentKey: 'undue_payment_iva',
  },
  isr: {
    taxKey: 'isr',
    unduePaymentKey: 'undue_payment_isr',
  },
};

// Props type for functions using tax-related calculations
interface TaxCalculationProps {
  declaration: Declaration;
  workflow: Workflow | null;
  taxType: TaxType;
}

/**
 * Calculates the amount in favor or against based on the tax type.
 */
export const calculateInFavorOrAgainst = ({
  declaration,
  workflow,
  taxType,
}: TaxCalculationProps): number => {
  const { totalKey, favorKey, unduePaymentKey } = taxKeysByType[taxType];

  // Early return if recalculation is not required
  if (!!workflow && !recalculationStatuses.includes(workflow.status)) {
    return getInFavorOrAgainst({
      declaration,
      totalKey,
      favorKey,
      unduePaymentKey,
    });
  }

  // Extract values from declaration
  const { [totalKey]: totalTaxOfPeriod, declarationPayers } =
    camelcaseKeys(declaration);

  // Calculate amounts for transaction type
  const amounts = calculateAmountsByTransactionType(declarationPayers ?? []);
  const {
    [transactionKeysByType[taxType].taxKey]: previousTaxTotal,
    [transactionKeysByType[taxType].unduePaymentKey]: unduePayment,
  } = amounts;

  // Special case for ISR when total tax of the period is less than or equal to 0
  if (taxType === 'isr' && +totalTaxOfPeriod <= 0) {
    return 0;
  }

  // Final calculation: total tax minus undue payment and previous tax total
  return +totalTaxOfPeriod - unduePayment - previousTaxTotal;
};

/**
 * Retrieves the undue payment amount based on the tax type.
 */
export const getUnduePaymentByTaxType = ({
  declaration,
  workflow,
  taxType,
}: TaxCalculationProps): number => {
  const declarationValues = camelcaseKeys(declaration);

  // Early return if recalculation is not required
  if (!!workflow && !recalculationStatuses.includes(workflow.status)) {
    return +(declarationValues[taxKeysByType[taxType].unduePaymentKey] || 0);
  }

  // Calculate amounts for transaction type
  const amounts = calculateAmountsByTransactionType(
    declarationValues.declarationPayers ?? [],
  );

  return amounts[transactionKeysByType[taxType].unduePaymentKey];
};

/**
 * Retrieves the previous tax total based on the tax type.
 */
export const getPreviousTaxTotalByTaxType = ({
  declaration,
  workflow,
  taxType,
}: TaxCalculationProps): number => {
  const declarationValues = camelcaseKeys(declaration);

  // Early return if recalculation is not required
  if (!!workflow && !recalculationStatuses.includes(workflow.status)) {
    return +(declarationValues[taxKeysByType[taxType].favorKey] || 0);
  }

  // Calculate amounts for transaction type
  const amounts = calculateAmountsByTransactionType(
    declarationValues.declarationPayers ?? [],
  );

  // Return previous tax total
  return amounts[transactionKeysByType[taxType].taxKey];
};
