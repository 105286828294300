import {
  useRef,
  KeyboardEvent as ReactKeyboardEvent,
  useState,
  useEffect,
} from 'react';
import { Command } from 'cmdk';
import { Modal } from 'reactstrap';
import { Flex, Button } from '@konta/ui';
import { useCommandMenuContext } from '@providers/CommandMenuProvider';
import { ArrowLeftLineIcon } from '@konta/icons';
import ClientsPage from './ClientsPage';
import SuppliersPage from './SuppliersPage';
import DeclarationsPage from './DeclarationsPage';
import HelpCenterPage from './HelpCenterPage';
import HomePage from './HomePage';
import './styles.scss';
import EmptyState from './EmptyState';

const pageTranslation = {
  home: 'Inicio',
  clients: 'Clientes',
  suppliers: 'Proveedores',
  declarations: 'Declaraciones',
  helpCenter: 'Centro de ayuda',
};

export default function CommandMenu() {
  const {
    showCommandMenu,
    toggleShowCommandMenu,
    inputSearchRef,
    focusSearchInput,
    pages,
    popPage,
    pushPage,
  } = useCommandMenuContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const activePage = pages[pages.length - 1];
  const isHome = activePage === 'home';
  const isHelpCenter = activePage === 'helpCenter';
  const hasInformationText = inputValue.length < 3 && isHome;
  const hasBackButton = pages.length > 1;

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = '';
        }
      }, 100);

      setInputValue('');
    }
  }

  useEffect(() => {
    if (pages.length) {
      focusSearchInput();
    }
  }, [pages]);

  return (
    <Modal
      autoFocus={false}
      centered
      size="lg"
      isOpen={showCommandMenu}
      toggle={toggleShowCommandMenu}
      wrapClassName="modal-backdrop-blur"
    >
      <div className="konta-cmdk">
        <Command
          loop
          shouldFilter={!isHelpCenter}
          ref={ref}
          onKeyDown={(e: ReactKeyboardEvent) => {
            const inputSearchValue = inputSearchRef.current?.value || '';
            if (e.key === 'Enter') {
              bounce();
            }

            if (isHome && e.shiftKey && e.key === 'C') {
              e.preventDefault();
              pushPage('clients');
              bounce();
            }
            if (isHome && e.shiftKey && e.key === 'P') {
              e.preventDefault();
              pushPage('suppliers');
              bounce();
            }

            if (isHome || inputSearchValue) {
              return;
            }

            if (e.key === 'Backspace') {
              e.preventDefault();
              popPage();
              bounce();
            }
          }}
        >
          <div>
            {pages.map((p) => (
              <div key={p} cmdk-badge="">
                {/* @ts-ignore TODO: create dict when all pages have been defined */}
                {pageTranslation[p] || p}
              </div>
            ))}
          </div>
          <Flex
            align="center"
            gap={6}
            css={{ pl: hasBackButton ? '$16' : '$0', mb: '$12' }}
            className="overflow-hidden"
          >
            {hasBackButton && (
              <Button
                variant="text"
                icon
                onClick={() => {
                  popPage();
                  bounce();
                }}
                aria-label="Regresar"
                size="m"
              >
                <ArrowLeftLineIcon />
              </Button>
            )}
            <Command.Input
              ref={inputSearchRef}
              autoFocus
              placeholder="Buscar"
              onValueChange={(value) => {
                setInputValue(value);
              }}
            />
          </Flex>
          {hasInformationText && (
            <div className="information-text">
              <span>Escriba más de 3 caracteres para iniciar la búsqueda</span>
            </div>
          )}
          <Command.List>
            {activePage === 'home' && <HomePage />}
            {activePage === 'clients' && <ClientsPage />}
            {activePage === 'suppliers' && <SuppliersPage />}
            {activePage === 'declarations' && <DeclarationsPage />}
            {activePage === 'helpCenter' && <HelpCenterPage />}
            <EmptyState />
          </Command.List>
        </Command>
      </div>
    </Modal>
  );
}
