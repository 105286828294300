import { ComponentProps, memo } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useToggle } from 'rooks';
import { EyeLineIcon, EyeOutlineIcon } from '@konta/icons';
import { Modal, ModalHeader } from 'reactstrap';
import { Button, Flex, ModalContent, Text } from '@konta/ui';
import PreviewDocument from '@components/PreviewDocument';

interface PreviewPdfBtnModalProps {
  title: string;
  file: string | File | FileWithPath | null;
  onlyIcon?: boolean;
  buttonProps?: Omit<ComponentProps<typeof Button>, 'children'>;
  withIframaAsFallback?: boolean;
}

function PreviewPdfBtnModal({
  title,
  onlyIcon = false,
  file,
  buttonProps,
  withIframaAsFallback = false,
}: PreviewPdfBtnModalProps) {
  const [open, toggleOpen] = useToggle();

  return (
    <Flex>
      {onlyIcon ? (
        <Button
          size="xs"
          variant="outlined"
          icon
          noFill
          onClick={toggleOpen}
          {...buttonProps}
        >
          <EyeLineIcon color="#667085" />
        </Button>
      ) : (
        <Button
          size="2xs"
          color="gray"
          onClick={toggleOpen}
          variant="outlined"
          leftIcon={<EyeOutlineIcon />}
          {...buttonProps}
        >
          <Text>Visualizar</Text>
        </Button>
      )}
      {open && (
        <Modal isOpen={open} toggle={toggleOpen} size="lg" width="auto">
          <ModalHeader toggle={toggleOpen}> {title} </ModalHeader>
          <ModalContent css={{ minHeight: '400px', display: 'flex' }}>
            <PreviewDocument
              withIframaAsFallback={withIframaAsFallback}
              maxWidth={800}
              withShadow
              defaultFile={file}
            />
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default memo(PreviewPdfBtnModal);
