import { ReactNode } from 'react';
import { Button, CSS, HoverCard } from '@konta/ui';
import {
  StyledButtonContainer,
  styledButtonCss,
  styledFillButtonCss,
} from './styled';

interface ButtonIconWithTooltipProps {
  action: () => void;
  icon: ReactNode;
  tooltipText: ReactNode;
  disabled?: boolean;
  styledButtonContainerCss?: CSS;
  noFill?: boolean;
}

export default function ButtonIconWithTooltip({
  action,
  disabled,
  icon,
  tooltipText,
  styledButtonContainerCss,
  noFill = true,
}: ButtonIconWithTooltipProps) {
  return (
    <StyledButtonContainer css={styledButtonContainerCss}>
      <HoverCard
        side="top"
        openDelay={0}
        triggerElement={
          <Button
            size="xs"
            icon
            noFill={noFill}
            variant="text"
            onClick={action}
            disabled={disabled}
            css={noFill ? styledButtonCss : styledFillButtonCss}
          >
            {icon}
          </Button>
        }
      >
        {tooltipText}
      </HoverCard>
    </StyledButtonContainer>
  );
}
