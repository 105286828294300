import { styled } from '@konta/ui';

export const CardContainer = styled('div', {
  display: 'flex',
  padding: '24px 20px',
  backgroundColor: '$primary100',
  alignItems: 'center',
  gap: '24px',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid $gray200',
  background: '#FCFCFD',
  '@<lg': {
    flexWrap: 'wrap',
  },
});

export const CardTitle = styled('span', {
  color: '$gray700',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '28px',
  width: '100%',
});

export const CardBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
});

export const CardBodyItem = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  width: '100%',
  '& svg': {
    path: {
      stroke: '$primary700',
    },
  },
});

export const CardBodyText = styled('span', {
  color: '$gray700',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
});

export const CardFooterText = styled('span', {
  color: '$gray700',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
});

export const ImageContainer = styled('div', {
  position: 'relative',
  width: '240px',
  height: '185px',
  '& svg': {
    width: '240px',
    height: '185px',
  },
});
