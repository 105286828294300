const getPathsFromMatch = (location) => {
  const path = location.pathname.substr(1);
  let paths = path.split('/');
  if (paths[paths.length - 1].indexOf(':') > -1) {
    paths = paths.filter((x) => x.indexOf(':') === -1);
  }
  return paths;
};

export const getModuleTitle = (location) => {
  const paths = getPathsFromMatch(location);
  return paths[1];
};

const tabsLinksAndLabels = (isRejected, arrearsV1Enabled) => {
  const enableMonthlyView = !isRejected;
  return {
    invoicing: {
      links: [
        'app/invoicing',
        'app/invoicing/contacts',
        'app/invoicing/suppliers',
        'app/invoicing/products',
      ],
      labels: [
        'invoicing.tabs.invoicing',
        'invoicing.tabs.contacts',
        'invoicing.tabs.suppliers',
        'invoicing.tabs.products',
      ],
    },
    declaraciones: {
      links: [
        enableMonthlyView && 'app/declaraciones',
        'app/declaraciones/anuales',
      ].filter(Boolean),
      labels: [
        enableMonthlyView && 'workflows.tabs.summary',
        'workflows.tabs.annuals',
      ].filter(Boolean),
    },
  };
};

export const getModuleTabsWithLinks = (location, isRejected) => {
  const paths = getPathsFromMatch(location);
  const tabsAndLinks = tabsLinksAndLabels(isRejected)[paths[1]];
  return tabsAndLinks;
};

export const isPathOnCurrentPageId = (id, location) => {
  const paths = getPathsFromMatch(location);
  return paths[paths.length - 1] === id;
};
