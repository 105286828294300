import { ReactGrid } from '@silevis/reactgrid';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import useGrid from './useGrid';
import { NumberWithIconCellTemplate } from '../TemplateCells/NumberWithIcon';

interface AnnualDeclarationGridProps {
  satKeys: number[];
  workflows: Workflow[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  title: string;
  toggleOpenPersonalDeductionModal: () => void;
}
export default function AnnualDeclarationGrid({
  satKeys,
  workflows,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  title,
  toggleOpenPersonalDeductionModal,
}: AnnualDeclarationGridProps) {
  const { rows, columns, onSelectionChanged } = useGrid({
    satKeys,
    onHandleEntriesView,
    workflows,
    period,
    selectedAnnualDeclaration,
    title,
    toggleOpenPersonalDeductionModal,
  });

  return (
    <div
      style={{ height: '100%', overflow: 'auto' }}
      className="taxes-react-grid"
    >
      <ReactGrid
        onSelectionChanged={onSelectionChanged}
        enableFillHandle={false}
        rows={rows}
        columns={columns}
        stickyTopRows={1}
        stickyLeftColumns={2}
        customCellTemplates={{
          numberWithIcon: new NumberWithIconCellTemplate(),
        }}
      />
    </div>
  );
}
