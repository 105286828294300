import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Flex, Tab, Tabs } from '@konta/ui';
import useFeatureFlag from 'backoffice/hooks/useFeatureFlag';
import { TAX_MODULE } from 'backoffice/constants/featureFlags';

const customerTabs = [
  {
    title: 'Información de la cuenta',
    id: 'account-information',
  },
  {
    title: 'Situación fiscal',
    id: 'fiscal-situation',
  },
  {
    title: 'Impuestos',
    id: 'taxes',
  },
  {
    title: 'Configuración',
    id: 'settings',
  },
  {
    title: 'Atrasos',
    id: 'tax-arrears',
  },
  {
    title: 'Suscripción',
    id: 'subscription',
  },
  {
    title: 'Onboarding',
    id: 'onboardin-procedures',
  },
  {
    title: 'Configuración fiscal',
    id: 'fiscal-settings',
  },
  {
    title: 'Anuales',
    id: 'annual-declarations',
  },
];

export default function CustomerTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const tab = useMemo(() => {
    if (!location?.pathname) {
      return '';
    }
    const currentTabId = location.pathname.split('/')[3];
    if (!currentTabId) {
      return '';
    }
    return currentTabId;
  }, [location?.pathname]);

  const [isTaxModuleEnabled] = useFeatureFlag(TAX_MODULE);

  const filteredTabs = useMemo(() => {
    if (!isTaxModuleEnabled) {
      return customerTabs.filter((_tab) => _tab.id !== 'taxes');
    }
    return customerTabs;
  }, [isTaxModuleEnabled]);

  return (
    <Flex column gap={10}>
      <Tabs
        value={tab}
        onChange={(newTab) => {
          navigate(`/customers/${customerId || 0}/${newTab as string}`, {
            replace: true,
          });
        }}
        variant="text"
        indicator="bottom"
        css={{ overflowX: 'auto' }}
      >
        {filteredTabs.map((customerTab) => (
          <Tab
            key={customerTab.id}
            css={{ fontSize: '$s' }}
            id={customerTab.id}
          >
            {customerTab.title}
          </Tab>
        ))}
      </Tabs>
      <Outlet />
    </Flex>
  );
}
