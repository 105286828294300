import {
  ComponentProps,
  FocusEvent,
  FocusEventHandler,
  SyntheticEvent,
  useEffect,
  useMemo,
} from 'react';
import dayjs from 'dayjs';
import { ReactDatePickerProps } from 'react-datepicker';
import { useField, useFormikContext } from 'formik';
import { Flex, Text } from '@konta/ui';
import DatePicker from 'shared/components/DatePicker';

interface FormikDatePickerProps
  extends Omit<
    ComponentProps<typeof DatePicker>,
    'label' | 'name' | 'placeholder' | 'onChange' | 'onBlur'
  > {
  label: string;
  name: string;
  placeholder?: string;
  onChange?: ReactDatePickerProps['onChange'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
}
export default function FormikDatePicker({
  name,
  label,
  disabled,
  onChange,
  onBlur,
  id,
  ...restProps
}: FormikDatePickerProps) {
  const formik = useFormikContext();
  const [field, meta, { setValue, setTouched }] = useField({
    name,
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleChange = (
    date: Date | null,
    e: SyntheticEvent<string> | undefined,
  ) => {
    setValue(date);
    if (onChange) {
      onChange(date, e);
    }
  };

  const touched = meta.touched || formik.submitCount > 0;
  const error = meta.error && touched ? meta.error : null;
  const selected = useMemo(() => {
    const date = field?.value as Date;
    if (!date) {
      return null;
    }
    return dayjs(date).toDate();
  }, [field?.value]);

  useEffect(() => {
    if (selected && !touched) {
      setTouched(true, true);
    }
  }, [selected]);

  return (
    <Flex direction="column" gap={6} css={{ flex: 1 }}>
      <DatePicker
        {...restProps}
        className="new-datepicker"
        wrapperClassName="new-datepicker-wrapper"
        name={name}
        id={id || name}
        label={label}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        inputCss={{ alignItems: 'center' }}
        selected={selected}
        onChangeRaw={() => {
          setTouched(true, true);
        }}
      />
      {error && (
        <Text xs lineHeight="xs" color="error500">
          {error}
        </Text>
      )}
    </Flex>
  );
}
