/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState, useEffect } from 'react';
import {
  DataTable,
  Card,
  CardContent,
  Flex,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Button,
  Text,
  styled,
} from '@konta/ui';
import {
  Edit2OutlineIcon,
  TrashCanOutlineIcon,
  ArrowNarrowLeftLineIcon,
} from '@konta/icons';
import { useDispatch } from 'react-redux';
import { ConfirmModal } from '@components/Modal';
import { deleteClientsSuppliers } from '@redux/actions';
import ClientModalForm from '@components/ClientModalForm';
import { useToggle } from 'usehooks-ts';
import Breadcrumbs from '@components/Breadcrumbs';
import InvoiceTransactionGraph from '@components/InvoiceTransactionGraph';
import { useLocation } from 'react-router-dom';

export const BreadcrumbsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  pb: '22px',
  background: '$white',
  width: '100%',
  px: '16px',
});

const ContainerBackButton = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '16px 16px',
  pl: '0',
  alignItems: 'center',
  borderTop: '1px solid $gray200',
  background: '$white',
  position: 'fixed',
  bottom: 0,
  zIndex: 1000,
  '@md': {
    padding: '24px 16px',
  },
});

export default function ClientsProvidersCard({
  clients,
  suppliers,
  loading,
  taxable_entity,
  view,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(
    location.state?.selectedItem || null,
  );
  const [selectedClient, setSelectedClient] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle();
  const [isClientsOpen, setIsClientsOpen] = useState(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.selectedItem) {
        setSelectedItem(location.state.selectedItem);
      }
    }
  }, [location.state]);

  const toggleClients = (result) => {
    setIsClientsOpen(!isClientsOpen);
  };

  const editClient = (client) => {
    setSelectedClient(client);
    toggleClients();
  };

  const deleteClient = (product_id) => {
    dispatch(deleteClientsSuppliers(taxable_entity, product_id));
    toggleOpenDeleteModal();
  };

  const columns = useMemo(
    () => [
      {
        header: 'Nombre legal',
        accessorKey: 'legal_name',
        width: 350,
        label: 'Nombre legal',
        cell: ({ renderValue }) => {
          return <p className="list-item-heading">{renderValue() || ''}</p>;
        },
      },
      {
        header: 'RFC',
        accessorKey: 'rfc',
        label: 'RFC',
        cell: ({ renderValue }) => (
          <p className="list-item-heading text-capitalize">
            {renderValue() || ''}
          </p>
        ),
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        label: 'Acciones',
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size="2xs"
                  color="gray"
                  variant="minimal"
                  squaredPadding
                >
                  <div
                    style={{
                      fontSize: '25px',
                      paddingBottom: '50%',
                    }}
                  >
                    &#x2026;
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {view === 'clients' && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedClient(row.original);
                    }}
                  >
                    <Flex align="center">
                      <Edit2OutlineIcon />
                      <Text css={{ mr: '$16', ml: '$12' }}>Editar</Text>
                    </Flex>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteItem(row.original.id);
                    toggleOpenDeleteModal();
                  }}
                >
                  <Flex align="center">
                    <TrashCanOutlineIcon />
                    <Text css={{ mr: '$16', ml: '$12' }}>Eliminar</Text>
                  </Flex>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view],
  );

  const renderView = () => {
    if (view === 'clients') {
      return (
        <DataTable
          loading={loading}
          noDataText="Sin información."
          defaultPageSize={10}
          data={clients}
          columns={columns}
          dataTableId="clients"
          onRowClick={(row) =>
            setSelectedItem({
              rfc: row.original.rfc,
              legalName: row.original.legal_name,
              isEmitted: true,
            })
          }
        />
      );
    }
    if (view === 'suppliers') {
      return (
        <DataTable
          loading={loading}
          noDataText="Sin información"
          defaultPageSize={10}
          data={suppliers}
          columns={columns}
          dataTableId="suppliers"
          onRowClick={(row) =>
            setSelectedItem({
              rfc: row.original.rfc,
              legalName: row.original.legal_name,
              isEmitted: false,
            })
          }
        />
      );
    }
    return null;
  };

  const breadcrumbs = useMemo(() => {
    if (!selectedItem) {
      return [];
    }
    const label = selectedItem.isEmitted ? 'Clientes' : 'Proveedores';
    return [
      {
        label,
        isActive: false,
        onClick: () => {
          setSelectedItem(null);
        },
      },
      {
        label: `${selectedItem.rfc}`,
        onClick: () => {},
        isActive: true,
      },
    ];
  }, [selectedItem]);

  return (
    <>
      <Card css={{ pt: '0' }}>
        <CardContent css={{ p: 0, pt: '20px' }}>
          {selectedItem ? (
            <>
              <BreadcrumbsContainer>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </BreadcrumbsContainer>
              <InvoiceTransactionGraph
                isModal={false}
                open
                toggleOpen={() => {}}
                selectedData={selectedItem}
              />
              <ContainerBackButton>
                <Button
                  leftIcon={<ArrowNarrowLeftLineIcon />}
                  variant="outlined"
                  onClick={() => {
                    setSelectedItem(null);
                  }}
                  size={{ '@initial': 'xs', '@md': 's' }}
                >
                  Regresar
                </Button>
              </ContainerBackButton>
            </>
          ) : (
            renderView()
          )}
        </CardContent>
      </Card>
      {view === 'clients' && (
        <ClientModalForm
          relationship="client"
          isOpen={!!selectedClient}
          client={selectedClient}
          onSubmit={toggleClients}
          toggle={() => setSelectedClient(null)}
        />
      )}
      <ConfirmModal
        title={`Eliminar ${view === 'clients' ? 'Cliente' : 'Proveedor'}`}
        open={openDeleteModal}
        onAccept={() => deleteClient(deleteItem)}
        onCancel={toggleOpenDeleteModal}
        onClose={toggleOpenDeleteModal}
      >
        {`¡Estas a punto de borrar ${
          view === 'clients' ? 'un cliente' : 'un proveedor'
        } de tu catálogo!`}
      </ConfirmModal>
    </>
  );
}
