import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import CfdisTotalsModal from 'shared/components/CfdisTotalsModal';
import useAccumulatedIncomesAndExpenses from 'shared/hooks/useAccumulatedIncomesAndExpenses';
import { MONTHS } from '@constants/time';
import { AccumulatedIncomeAndExpenseFilters } from 'types/entities';

interface InvoiceTransactionGraphProps {
  open: boolean;
  toggleOpen: () => void;
  selectedData: {
    rfc: string;
    legalName: string;
    isEmitted: boolean;
  };
  isModal?: boolean;
}

export default function InvoiceTransactionGraph({
  open,
  toggleOpen,
  selectedData,
  isModal = true,
}: InvoiceTransactionGraphProps) {
  const [filters, setFilters] = useState<Record<string, string>>({
    type: 'day',
    period: 'currentYear',
  });

  const { isEmitted } = selectedData;

  const clientRfcParam = isEmitted ? 'receiver_rfc_eq' : 'issuer_rfc_eq';

  const { incomesDataSet, isLoading, expensesDataSet } =
    useAccumulatedIncomesAndExpenses({
      filter: filters.period as AccumulatedIncomeAndExpenseFilters,
      params: {
        accumulate_periods: filters.type === 'accumulated',
        [`q[${clientRfcParam}]`]: selectedData.rfc,
      },
    });

  const seriesGraph = useMemo<'area' | 'line' | 'none'>(() => {
    if (filters.type === 'accumulated') {
      return 'line';
    }
    return 'none';
  }, [filters.type]);

  const dataSet = isEmitted ? incomesDataSet : expensesDataSet;

  const title = isEmitted
    ? `Facturas emitidas de ${selectedData.legalName}`
    : `Facturas recibidas de ${selectedData.legalName}`;

  const completeDataSet = useMemo(() => {
    const firstDataWithValues = dataSet.data.find((item) => item.value > 0);
    const extraDataKeys = firstDataWithValues
      ? Object.keys(firstDataWithValues.extraData)
      : ['total'];

    const filledData = ['currentYear', 'lastYear'].includes(filters.period)
      ? MONTHS.map((month) => {
          const existingData = dataSet.data.find((item) => item.name === month);
          if (existingData) return existingData;

          const extraData = extraDataKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: 0,
            }),
            {},
          );

          return {
            name: month,
            value: 0,
            extraData,
          };
        })
      : Array.from({ length: dayjs().daysInMonth() }, (_, i) => {
          const dayNumber = (i + 1).toString();
          const existingData = dataSet.data.find(
            (item) => item.name === dayNumber,
          );
          if (existingData) return existingData;

          const extraData = extraDataKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: 0,
            }),
            {},
          );

          return {
            name: dayNumber,
            value: 0,
            extraData,
          };
        });

    return {
      ...dataSet,
      data: filledData,
    };
  }, [dataSet, filters.period]);

  return (
    <CfdisTotalsModal
      isModal={isModal}
      open={open}
      toggleOpen={toggleOpen}
      title={title}
      id="test1"
      dataSet={completeDataSet}
      filters={filters}
      setFilters={setFilters}
      series={seriesGraph}
      invoiceType={isEmitted ? 'incomes' : 'expenses'}
      selectedData={selectedData}
      datasetLoading={isLoading}
    />
  );
}
