import React from 'react';
import {
  Flex,
  Text,
  Divider,
  InputAction,
  ModalContent,
  Button,
} from '@konta/ui';
import dayjs from 'dayjs';
import {
  CURRENCY_OPTIONS,
  INVOICE_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  USE_CFDI_OPTIONS,
  FISCAL_RESIDENCE_OPTIONS,
  PERIODICITY_OPTIONS,
  EXPORT_CODE_OPTIONS,
} from '@constants/invoicing';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import FormikKontaSelect from '@components/FormikKontaSelect';
import FormikRegimeSelect from '@components/FormikRegimeSelect';
import FormikClientInvoiceSelect from '@components/FormikClientInvoiceSelect';
import FormikTextInput from '@components/FormikTextInput';
import FormikUserRegimeSelect from '@components/FormikUserRegimeSelect';
import { Collapse, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import FormikPeriodicityMonthField from '@components/FormikPeriodicityMonthField';
// eslint-disable-next-line import/extensions
import FormikDatePicker from 'shared/components/FormikDatePicker';

const rowCss = {
  label: {
    marginBottom: 0,
  },
  padding: '8px 0',
  flexDirection: 'column',
  gap: '$8',
  '@md': {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  '>*': {
    '@md': {
      flex: 1,
      width: '320px',
    },
  },
};

// NOTE: add or remove conditions to hasErrorsInDetails in NewInvoiceModal component when new fields are added or removed

/**
 * @param {{ invoice: import('@hooks/useInvoiceForm').UseInvoice, isOpen: boolean, toggle: Function, extraDetailsOpen: boolean, toggleExtraDetails: Function }} param0
 */
export default function InvoiceDetailsModal({
  isOpen,
  toggle,
  invoice,
  extraDetailsOpen,
  toggleExtraDetails,
}) {
  const { creditNoteInvoice, currency, client, invoiceType, periodicity } =
    invoice.formik.values;

  const disableCfdiUsage =
    invoice.genericRfc && !!creditNoteInvoice?.value.cfdi_use_id;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        toggleExtraDetails(false);
      }}
      size="lg"
      width="auto"
      style={{ width: 'min-width: 976px' }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
          toggleExtraDetails(false);
        }}
      >
        Datos de facturación
      </ModalHeader>
      <ModalContent>
        <Text bold color="black" size="xl" lineHeight="xl">
          Datos generales
        </Text>

        <Divider />

        <Flex css={rowCss}>
          <FormikKontaSelect
            name="invoiceType"
            label="Tipo de factura"
            labelHelpPopover={
              <Flex direction="column">
                <Text>Es el tipo de factura que quieres realizar.</Text>
                <Text>
                  <Text bold>Ingreso:</Text> Si vendiste un producto o servicio.
                </Text>
                <Text>
                  <Text bold>Egreso:</Text> Si te realizaron una devolución o
                  estás dando un descuento.
                </Text>
              </Flex>
            }
            options={INVOICE_TYPE_OPTIONS}
            onChange={invoice.setInvoiceType}
          />
          <FormikKontaSelect
            name="paymentMethod"
            label="Método de pago"
            labelHelpPopover={
              <Flex direction="column">
                <Text>
                  Es la manera en que se recibirá el pago de esta factura. (Aún
                  no recibes el pago total).
                </Text>
                <Text bold>Pago en una Sola Exhibición:</Text> De Contado (Ya
                recibiste el pago total)
                <Text />
                <Text>
                  <Text bold>Pago en Parcialidades o Diferido:</Text> Crédito de
                  tienda
                </Text>
              </Flex>
            }
            options={PAYMENT_METHOD_OPTIONS}
            onChange={invoice.setInvoicePaymentMethod}
          />
          <FormikKontaSelect
            isDisabled={invoice.isPPD}
            name="paymentType"
            labelHelpPopover={
              <Flex>
                <Text>
                  Es la manera en que recibiste el pago de tu producto o
                  servicio.{' '}
                  <Text bold>
                    En caso de factura en PPD la Forma de Pago es “Por Definir”
                  </Text>
                </Text>
              </Flex>
            }
            label="Forma de pago"
            options={PAYMENT_TYPE_OPTIONS}
          />
        </Flex>
        {invoiceType.value === 'E' && (
          <Flex css={rowCss}>
            <FormikClientInvoiceSelect
              newDesign
              name="creditNoteInvoice"
              onChange={invoice.setCreditNoteInvoice}
              label="Facturas"
              client={client?.value}
            />
          </Flex>
        )}
        <Flex css={rowCss}>
          <FormikRegimeSelect
            newDesign
            name="clientRegime"
            label="Régimen de cliente"
            labelHelpPopover={
              <Flex>
                <Text>
                  El Régimen Fiscal que tenga registrado tu cliente en su
                  Constancia de Situación Fiscal.
                </Text>
              </Flex>
            }
            availableRegimes={client?.value.fiscal_regimes}
            isDisabled={!client}
          />
          <FormikKontaSelect
            name="cfdiUsage"
            label="Uso CFDI"
            labelHelpPopover={
              <Flex>
                <Text>
                  El Uso que tu cliente le va a dar al CFDI. (Usualmente “Gastos
                  en General”)
                </Text>
              </Flex>
            }
            disabled={disableCfdiUsage}
            options={USE_CFDI_OPTIONS}
          />
          <FormikKontaSelect
            name="exportCode"
            label="Clave de exportación"
            options={EXPORT_CODE_OPTIONS}
          />
        </Flex>
        {invoice.generalPublicRfc && invoiceType.value !== 'E' && (
          <Flex css={rowCss}>
            <FormikKontaSelect
              name="periodicity"
              label="Periodicidad"
              labelHelpPopover={
                <Flex>
                  <Text>
                    Indica la frecuencia o periodo que abarcan las ventas de tu
                    Factura Global.
                  </Text>
                </Flex>
              }
              options={PERIODICITY_OPTIONS}
              onChange={invoice.setPeriodicity}
            />

            <FormikPeriodicityMonthField
              newDesign
              name="periodicityMonth"
              label="Mes"
              labelHelpPopover={
                <Flex>
                  <Text>
                    Indica a que mes del año fiscal corresponden las ventas de
                    tu Factura Global.
                  </Text>
                </Flex>
              }
              periodicity={periodicity?.value}
            />

            <FormikDatePicker
              name="periodicityYear"
              label="Año"
              dateFormat="yyyy"
              showYearPicker
              yearItemNumber={3}
              maxDate={dayjs().endOf('year').toDate()}
              labelHelpPopover={
                <Flex>
                  {' '}
                  <Text>
                    Indica a que año fiscal corresponden las ventas de tu
                    Factura Global.
                  </Text>
                </Flex>
              }
            />
          </Flex>
        )}

        <Flex>
          <Text bold color="black" size="xl" lineHeight="xl">
            Opciones adicionales
          </Text>
          <InputAction onClick={() => toggleExtraDetails(!extraDetailsOpen)}>
            {extraDetailsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </InputAction>
        </Flex>

        <Divider />

        <Collapse isOpen={extraDetailsOpen}>
          <Flex css={rowCss}>
            <FormikTextInput
              name="orderNumber"
              label="Número de orden"
              labelHelpPopover={
                <Flex>
                  <Text>
                    Un número que te ayude a identificar el producto vendido,
                    usualmente un SKU. <Text bold>Campo NO obligatorio</Text>
                  </Text>
                </Flex>
              }
            />
            <FormikTextInput
              name="invoiceNumber"
              label="Folio Interno"
              labelHelpPopover={
                <Text>
                  Un número que te ayude a identificar fácilmente está factura{' '}
                  <Text bold>Campo NO obligatorio</Text>
                </Text>
              }
            />
            <FormikDatePicker
              name="createdDate"
              label="Fecha de emisión"
              timeFormat="HH:mm"
              dateFormat="dd/MM/yyyy h:mm aa"
              timeInputLabel="Hora:"
              showTimeInput
              labelHelpPopover={
                <Text>
                  La fecha en que surte efectos está factura (Puedes seleccionar
                  hasta 72 horas atrás).
                </Text>
              }
            />
          </Flex>
          <Flex css={rowCss}>
            <FormikUserRegimeSelect
              newDesign
              label="Mi régimen fiscal"
              labelHelpPopover={
                <Flex>
                  <Text>
                    En caso de tener varios Regímenes Fiscales puedes
                    seleccionar a que Régimen Fiscal aplicara está factura.
                  </Text>
                </Flex>
              }
              name="fiscalRegime"
            />
            <FormikKontaSelect
              name="taxResidency"
              label="Residencia Fiscal"
              labelHelpPopover={
                <Text>
                  En caso de tener sucursales dentro y fuera de México, puedes
                  indicar en que parte del mundo se está realizando está
                  factura.
                </Text>
              }
              disabled={disableCfdiUsage}
              options={FISCAL_RESIDENCE_OPTIONS}
            />
            <FormikKontaSelect
              name="currency"
              label="Moneda"
              onChange={invoice.setCurrency}
              labelHelpPopover={
                <Flex>
                  <Text>
                    La moneda que se está utilizando para la transacción.
                    (Comúnmente “Peso Mexicano”)
                  </Text>
                </Flex>
              }
              options={CURRENCY_OPTIONS}
            />
          </Flex>
          <Flex css={rowCss}>
            {invoice.isForeignClient && (
              <FormikTextInput
                name="taxIdentityRegistrationNumber"
                label="Clave de identificación fiscal"
              />
            )}
            <FormikTextInput
              name="exchangeRate"
              label="Tipo de cambio"
              css={{
                maxWidth: invoice.isForeignClient ? 'auto' : '245px',
              }}
              labelHelpPopover={
                <Flex>
                  <Text>
                    En caso de que la Moneda con la que se recibió o recibirá el
                    pago sea distinta a la Moneda Nacional debes indicar un Tipo
                    de Cambio según el Diario Oficial de la Federación (DOF).
                  </Text>
                </Flex>
              }
              type="number"
              disabled={currency?.value === 'MXN'}
            />
          </Flex>
        </Collapse>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            toggle();
            toggleExtraDetails(false);
          }}
          color="primary"
        >
          Guardar y cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
