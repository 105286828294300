import React, { useMemo } from 'react';
import {
  Button,
  Tab,
  Tabs,
  Flex,
  Card,
  CardContent,
  CardHeader,
} from '@konta/ui';
import dayjs from 'dayjs';
import { INVOICE_TEMPLATE } from '@constants/featureFlags';
import useFeature from '@hooks/useFeature';
import { DatePickerPopover } from '@components';
import UserInvoicesTable from '@components/UserInvoicesTable';
import useCreateInvoiceDraft from '@hooks/useCreateInvoiceDraft';
import InvoiceBannerModal from '@components/InvoiceBannerModal';
import { useToggle } from 'rooks';
import useSubscriptions from '@hooks/useSubscriptions';
import useCfdiStore from 'store/cfdisStore';

export default function InvoicingIncome() {
  const [openBannerModal, toggleOpenBannerModal] = useToggle(true);
  const [invoiceTemplate] = useFeature(INVOICE_TEMPLATE);
  const { dateFilter, setDateFilter, selectedTab, setSelectedTab } =
    useCfdiStore((state) => state);
  const { subscriptions } = useSubscriptions();

  const hasNotActiveSubscription = useMemo(() => {
    return (
      subscriptions.every((subscription) =>
        ['cancelled', 'incomplete'].includes(subscription),
      ) || subscriptions.length === 0
    );
  }, [subscriptions]);

  const { createInvoiceDraft, loadingCreateInvoiceDraft } =
    useCreateInvoiceDraft();

  const handlePeriodChange = (value) => {
    setDateFilter(dayjs(value));
  };

  return (
    <>
      {hasNotActiveSubscription && (
        <InvoiceBannerModal
          visible={openBannerModal}
          onClose={toggleOpenBannerModal}
        />
      )}
      <Flex className="mb-4">
        <Button
          onClick={createInvoiceDraft}
          color="primary"
          loading={loadingCreateInvoiceDraft}
        >
          Nueva Factura
        </Button>
        <div className="position-relative ml-auto">
          <DatePickerPopover
            target="btn-datepicker"
            date={dateFilter.toDate()}
            handleChange={handlePeriodChange}
          />
        </div>
      </Flex>
      <Card css={{ pt: '0' }}>
        <CardHeader
          css={{ padding: '0' }}
          header={
            <Flex
              css={{
                flexDirection: 'column',
                '@sm': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={setSelectedTab}
                variant="text"
                indicator="top"
              >
                <Tab id="emitted">Emitidas</Tab>
                <Tab id="received">Recibidas</Tab>
                {invoiceTemplate && <Tab id="templates">Plantillas</Tab>}
              </Tabs>
            </Flex>
          }
        />
        <CardContent css={{ pt: '15px' }}>
          <UserInvoicesTable />
        </CardContent>
      </Card>
    </>
  );
}
