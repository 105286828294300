import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Calculator1Icon } from '@konta/icons';
import { Flex } from '@konta/ui';
import { CustomAccordion } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import PrimaryPhaseButton from '@components/PrimaryPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';

export default function SelectIncomeUploadWay() {
  const [selectedUploadIncomeMethod, setSelectedUploadIncomeMethod] =
    useState();

  const [toggleAccordionOpen, setToggleAccordionOpen] = useState({
    belvoToggle: false,
    manualToggle: false,
    transactionToggle: false,
  });

  useEffect(() => {
    if (selectedUploadIncomeMethod === 'belvo_connection') {
      setToggleAccordionOpen({
        belvoToggle: !toggleAccordionOpen.belvoToggle,
        manualToggle: false,
        transactionToggle: false,
      });
    } else if (selectedUploadIncomeMethod === 'upload_income_total') {
      setToggleAccordionOpen({
        manualToggle: !toggleAccordionOpen.manualToggle,
        belvoToggle: false,
        transactionToggle: false,
      });
    } else if (selectedUploadIncomeMethod === 'upload_transaction') {
      setToggleAccordionOpen({
        transactionToggle: !toggleAccordionOpen.transactionToggle,
        manualToggle: false,
        belvoToggle: false,
      });
    } else {
      setToggleAccordionOpen({
        belvoToggle: false,
        manualToggle: false,
        transactionToggle: false,
      });
    }
  }, [selectedUploadIncomeMethod]);

  const payload = useMemo(
    () => ({
      workflow: {
        next_phase:
          selectedUploadIncomeMethod === 'belvo_connection'
            ? 'primary'
            : 'secondary_phase',
        income_upload_way: selectedUploadIncomeMethod,
      },
    }),
    [selectedUploadIncomeMethod],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Selecciona el método para subir tus ingresos"
          description="Necesitamos capturar los ingresos que tuvo tu negocio. En esta vista podrás escoger la forma en la quieres subir el total de tus ingresos del mes."
          videoId="iRTC4eJMjAE"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <PrimaryPhaseButton
            payload={payload}
            disabled={!selectedUploadIncomeMethod}
          />
        </>
      }
    >
      <Flex align="stretch" direction="column">
        <CustomAccordion
          incomeMethod="upload_income_total"
          testId="manualIncome"
          isOpen={toggleAccordionOpen.manualToggle}
          selectedUploadIncomeMethod={selectedUploadIncomeMethod}
          setSelectedUploadIncomeMethod={setSelectedUploadIncomeMethod}
          titleIcon={() => {
            return <Calculator1Icon className="custom-icon-lg" />;
          }}
          title={() => <h3>Captura manualmente tus ingresos</h3>}
        >
          <Card>
            <CardBody>
              <h4>
                Esta opción te permite capturar manualmente los ingresos que
                tuvo tu negocio en este mes.
              </h4>

              <p>Para esto tienes que:</p>
              <p>1.- Obtener tu estado de cuenta</p>
              <p>2.- Sumar los ingresos que pertenecen a tu negocio</p>
              <p>3.- Capturar manualmente en la siguiente vista</p>
            </CardBody>
          </Card>
        </CustomAccordion>
      </Flex>
    </WorkflowLayout>
  );
}
