import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkflows from 'shared/hooks/useWorkflows';
import useTaxableEntity from '@hooks/useTaxableEntity';
import { AnnualDeclaration } from 'types/entities';
import usePreferredFiscalRegime from 'shared/hooks/usePreferredFiscalRegime';
import {
  PF_SAT_KEY,
  PFAE_SAT_KEY,
  RESICO_SAT_KEY,
  SALARIES_SAT_KEY,
} from 'shared/constants/grids';
import { useToggle } from 'rooks';

const views = {
  resume: 'resume',
  resumeByRegime: 'resume-by-regime',
  entries: 'entries',
};

interface UseRoutesResumeAnnualProps {
  customPeriod?: number;
  annualDeclarations?: AnnualDeclaration[];
  loadingAnnualDeclarations?: boolean;
}

interface StateParams {
  filters: {
    month: string;
    type: 'income' | 'expense';
  };
}

// Interfaz para los annual_declaration_totals
interface AnnualDeclarationTotal {
  source_type: string;
  created_by: string;
  [key: string]: any;
}

export default function useRoutesResumeAnnual({
  customPeriod,
  annualDeclarations,
  loadingAnnualDeclarations,
}: UseRoutesResumeAnnualProps) {
  const [view, setView] = useSearchParamsState('view', views.resumeByRegime);
  const [openPersonalDeductionModal, toggleOpenPersonalDeductionModal] =
    useToggle();
  const [currentAnnualDeclaration, setCurrentAnnualDeclaration] =
    useState<AnnualDeclaration | null>(null);
  const [regimeSatKeys, setRegimeSatKeys] = useState<number[]>([]);
  const { taxable_entity } = useTaxableEntity();
  const [filters, setFilters] = useState<Record<string, string>>({
    month: '',
    type: '',
  });

  const period = customPeriod ?? dayjs().subtract(1, 'year').format('YYYY');

  const { workflows, workflowsLoading } = useWorkflows({
    params: {
      'q[active_true]': 1,
      'q[start_date_gteq]': `${period}-01-01`,
      'q[start_date_lteq]': `${period}-12-31`,
    },
  });
  const declarations = useMemo(
    () =>
      workflows.flatMap((workflow) =>
        workflow.declarations.filter((declaration) => declaration.is_active),
      ),
    [workflows],
  );

  const { state } = useLocation();

  useEffect(() => {
    const savedState = state as StateParams;
    if (savedState?.filters) {
      setFilters(savedState.filters);
    }
  }, [state]);

  const currentAnnualDeclarations = useMemo(
    () =>
      annualDeclarations?.length &&
      annualDeclarations
        .filter((annual) => annual.period === +period)
        .map((annualDeclaration) => {
          const { status, annual_declaration_totals } = annualDeclaration;
          const createdBy = status === 'open' ? 'konta_app' : 'retool_app';

          return {
            ...annualDeclaration,
            annual_declaration_totals: annual_declaration_totals?.filter(
              (_declaration) => _declaration.created_by === createdBy,
            ),
          } as AnnualDeclaration;
        }),
    [annualDeclarations, period],
  );

  const resumeByRegimeBreadcrumbs = [
    {
      label: 'Resumen por regímenes',
      onClick: () => setView(views.resumeByRegime),
      isActive: view === views.resumeByRegime,
    },
  ];

  const entriesBreadcrumbs = [
    ...resumeByRegimeBreadcrumbs,
    {
      label: 'Detalle de facturación',
      onClick: () => setView(views.entries),
      isActive: view === views.entries,
    },
  ];

  const { preferredFiscalRegime, loadingPreferredFiscalRegime } =
    usePreferredFiscalRegime();

  const workflowsId = useMemo(
    () => workflows?.map((workflow) => workflow.id),
    [workflows],
  );

  // Definimos onHandleResumeByRegime con useCallback para evitar recrearla en cada renderizado
  const onHandleResumeByRegime = useCallback(
    (
      _regimeSatKeys: number[],
      _currentAnnualDeclaration: AnnualDeclaration,
    ) => {
      setRegimeSatKeys(_regimeSatKeys);
      setView(views.resumeByRegime);
      setCurrentAnnualDeclaration(_currentAnnualDeclaration);
    },
    [setRegimeSatKeys, setView, setCurrentAnnualDeclaration],
  );

  const onHandleEntriesView = useCallback(
    (month: string, type: 'income' | 'expense') => {
      setFilters({
        month,
        type,
      });
      setView(views.entries);
    },
    [setFilters, setView],
  );

  // Configurar automáticamente los regimeSatKeys y el currentAnnualDeclaration cuando tenemos datos
  // Usamos useMemo para calcular la declaración más adecuada y sus regímenes
  const initialDeclarationSetup = useMemo(() => {
    if (
      !Array.isArray(currentAnnualDeclarations) ||
      currentAnnualDeclarations.length === 0 ||
      regimeSatKeys.length > 0 ||
      currentAnnualDeclaration
    ) {
      return null;
    }

    // Seleccionamos la primera declaración como punto de partida
    const firstDeclaration = currentAnnualDeclarations[0];

    // Verificamos los regímenes fiscales presentes
    const annualTotals = firstDeclaration?.annual_declaration_totals || [];
    const hasPfaeSource = annualTotals.some(
      (total: AnnualDeclarationTotal) =>
        total.source_type === 'business_activity',
    );
    const hasPlatformsSource = annualTotals.some(
      (total: AnnualDeclarationTotal) =>
        total.source_type === 'digital_platform',
    );
    const hasSalariesSource = annualTotals.some(
      (total: AnnualDeclarationTotal) => total.source_type === 'salary',
    );
    const isResico =
      firstDeclaration?.fiscal_regime?.sat_key === RESICO_SAT_KEY;

    // Combinaciones PFAE + Plataformas desde las declaraciones
    const declarationHasPfae = declarations.some(
      (declaration) => declaration.fiscal_regime?.sat_key === PFAE_SAT_KEY,
    );
    const declarationHasPlatforms = declarations.some(
      (declaration) => declaration.fiscal_regime?.sat_key === PF_SAT_KEY,
    );
    const hasPfaeAndPlatformsFromDeclarations =
      (hasPfaeSource && hasPlatformsSource) ||
      (declarationHasPfae && declarationHasPlatforms);

    // Configurar los regimeSatKeys basado en los regímenes detectados
    let keys: number[] = [];

    if (hasPfaeAndPlatformsFromDeclarations) {
      keys = [PFAE_SAT_KEY, PF_SAT_KEY];
    } else if (hasPfaeSource) {
      keys = [PFAE_SAT_KEY];
    } else if (hasPlatformsSource) {
      keys = [PF_SAT_KEY];
    } else if (isResico) {
      keys = [RESICO_SAT_KEY];
    } else if (hasSalariesSource) {
      keys = [SALARIES_SAT_KEY];
    }

    // Si encontramos régimenes, retornamos la información para configurar el estado
    if (keys.length > 0) {
      return {
        declaration: firstDeclaration,
        keys,
      };
    }

    return null;
  }, [
    currentAnnualDeclarations,
    regimeSatKeys,
    currentAnnualDeclaration,
    declarations,
  ]);

  // useEffect más simple que aplica la configuración
  useEffect(() => {
    if (initialDeclarationSetup && view === views.resumeByRegime) {
      const { declaration, keys } = initialDeclarationSetup;
      setRegimeSatKeys(keys);
      setCurrentAnnualDeclaration(declaration);
    }
  }, [
    initialDeclarationSetup,
    view,
    setRegimeSatKeys,
    setCurrentAnnualDeclaration,
  ]);

  const isLoading =
    workflowsLoading ||
    loadingAnnualDeclarations ||
    loadingPreferredFiscalRegime;

  return {
    views,
    view,
    setRegimeSatKeys,
    regimeSatKeys,
    setView,
    onHandleResumeByRegime,
    filters,
    setFilters,
    resumeByRegimeBreadcrumbs,
    entriesBreadcrumbs,
    onHandleEntriesView,
    isLoading,
    workflows,
    workflowsId,
    taxableEntity: taxable_entity,
    period,
    annualDeclarations,
    currentAnnualDeclarations,
    currentAnnualDeclaration,
    preferredFiscalRegime,
    openPersonalDeductionModal,
    toggleOpenPersonalDeductionModal,
  };
}
