import React from 'react';
import NewForeignProductForm from './NewForeignProductForm';

export default function ConditionalForeignProductForm(props) {
  /*
   * Feature isNewVersionEnabled is no more used for this component
   * const [isCustomsValueEnabled] = useFeature(CUSTOM_VALUE);
   */

  return <NewForeignProductForm {...props} />;
}
