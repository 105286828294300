import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MonthlyWorkflows } from '@containers';
import AnnualDeclaration from '@routes/app/AnnualDeclarations';

export default function Workflows() {
  const taxable_entity = useSelector(
    ({ taxableEntity }) => taxableEntity.taxable_entity,
  );
  const isRejected = taxable_entity?.onboarding_detail?.is_rejected_user;
  const enableMonthlyView = !isRejected;
  return (
    <div className="main-container">
      <Routes>
        {enableMonthlyView && (
          <Route path="/" exact element={<MonthlyWorkflows />} />
        )}
        <Route
          path="anuales"
          exact
          element={<AnnualDeclaration isProcess={false} />}
        />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </div>
  );
}
