export const KONTA_SCORE = 'KONTA_SCORE';
export const DECLARATION_ENTRIES_BY_WORKFLOW =
  'DECLARATION_ENTRIES_BY_WORKFLOW';
export const TAX_DELAYS = 'TAX_DELAYS';
export const CLIENT_SUPPLIERS = 'CLIENT_SUPPLIERS';
export const FOREIGN_TAXES_PAYERS = 'FOREIGN_TAXES_PAYERS';
export const NEW_TAX_DELAYS = 'NEW_TAX_DELAYS';
export const DECLARATION_STATUS = 'DECLARATION_STATUS';
export const FLOWS = 'FLOWS';
export const TAXABLE_ENTITY_FLOWS = 'TAXABLE_ENTITY_FLOWS';
export const TAXABLE_ENTITY = 'TAXABLE_ENTITY';
export const SAT_DOC_DOWNLOADS = 'SAT_DOC_DOWNLOADS';
export const REQUIRED_PERIODS_TO_DECLARE = 'REQUIRED_PERIODS_TO_DECLARE';
