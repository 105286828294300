import { useMemo, useState, useEffect } from 'react';
import useAnnualsDeclarationQuery from '@hooks/useAnnualsDeclarationQuery';
import dayjs from 'dayjs';
import {
  BalancePaymentType,
  PreferencesAnnualProcessStep,
  ProcessType,
} from 'types/entities';
import toCurrency from '@util/toCurrency';
import usePreferencesAnnualForm, {
  PreferencesAnnualPayload,
} from '@hooks/usePreferencesAnnualForm';
import { RESICO_SAT_KEY } from 'shared/constants/grids';
import { getBase64FromFile } from 'backoffice/util/getBase64FromFile';
import useFeature from '@hooks/useFeature';
import { PAYMENT_IN_INSTALLMENTS_FOR_PREFERENCES_STEP } from '@constants/featureFlags';
import getServerDate from '@util/getServerDate';
import { calculateDifferredPaymentRows } from './calculateDifferredPaymentRows';

const oneYearAgo = dayjs().subtract(1, 'year').year();
type PreferencesAnnualAnswersStep = PreferencesAnnualProcessStep['answers'];

interface BankStatement {
  name: string;
  contents: string | null;
}

export default function usePreferencesAnnual() {
  const {
    loadingDeclaration: loadingAnnualDeclarations,
    declarations: annualDeclarations,
    isError,
  } = useAnnualsDeclarationQuery();
  const [payload, setPayload] = useState<PreferencesAnnualAnswersStep>();
  const [extraPayload, setExtraPayload] = useState<
    Record<string, BankStatement>
  >({});
  const [serverDateValue, setServerDateValue] = useState<string | null>(null);

  // Use useEffect to fetch and set the server date when component mounts
  useEffect(() => {
    const fetchServerDate = async () => {
      const date = (await getServerDate()) as string;
      setServerDateValue(date);
    };

    // Handle the promise to avoid linter error
    void fetchServerDate();
  }, []);

  const process = ProcessType.AnnualDeclarationProcess;

  const [isEnabledPaymentInInstallments] = useFeature(
    PAYMENT_IN_INSTALLMENTS_FOR_PREFERENCES_STEP,
  );

  const currentDeclaration = useMemo(
    () =>
      annualDeclarations?.find(
        (declaration) =>
          declaration?.period === oneYearAgo &&
          !declaration?.fiscal_regime?.sat_key,
      ),
    [annualDeclarations],
  );

  const currentDeclarationResico = useMemo(
    () =>
      annualDeclarations?.find(
        (declaration) =>
          declaration?.period === oneYearAgo &&
          declaration?.fiscal_regime?.sat_key === RESICO_SAT_KEY,
      ),
    [annualDeclarations],
  );

  const hasResico = Boolean(currentDeclarationResico);
  const hasDeclaration = Boolean(currentDeclaration);

  const hasError =
    isError || (!currentDeclaration && !currentDeclarationResico);

  const handleSubmit = async (values: PreferencesAnnualPayload) => {
    const {
      balance,
      bank_name,
      balance_resico,
      bank_name_resico,
      paymentPreference,
    } = values;

    const bankStatement = values.bank_statement as File;
    const bankStatementResico = values.bank_statement_resico as File;
    setExtraPayload({
      ...(bankStatement && {
        bank_statement: {
          name: bankStatement.name,
          contents: await getBase64FromFile(bankStatement),
        },
      }),
      ...(bankStatementResico && {
        bank_statement_resico: {
          name: bankStatementResico.name,
          contents: await getBase64FromFile(bankStatementResico),
          type: bankStatementResico.type,
        },
      }),
    });

    const hasTwoDeclarations = balance && balance_resico;

    const selectedBalance =
      balance === 'differred'
        ? (paymentPreference as BalancePaymentType)
        : (balance as BalancePaymentType) ||
          (balance_resico as BalancePaymentType);

    setPayload({
      balance: selectedBalance,
      ...(bank_name && { bank_name }),
      ...(hasTwoDeclarations &&
        balance_resico && {
          balance_resico: balance_resico as BalancePaymentType,
        }),
      ...(bank_name_resico && {
        bank_name_resico,
      }),
    });
  };

  const { formik } = usePreferencesAnnualForm({
    onSubmitForm: handleSubmit,
  });

  const isLoading = loadingAnnualDeclarations;

  const creditBalance = +(currentDeclaration?.profit_or_loss || 0);
  const creditBalanceResico = +(currentDeclarationResico?.profit_or_loss || 0);
  const balanceInFavor = creditBalance < 0;
  const balanceInFavorResico = creditBalanceResico < 0;
  const formatBalance = toCurrency(Math.abs(creditBalance));
  const formatBalanceResico = toCurrency(Math.abs(creditBalanceResico));
  const textForBalance = balanceInFavor ? 'favor' : 'pagar';
  const textForBalanceResico = balanceInFavorResico ? 'favor' : 'pagar';

  const enabledNextBtn = useMemo(() => {
    if (hasResico && hasDeclaration) {
      return (
        !!formik.values.balance &&
        !!formik.values.balance_resico &&
        formik.isValid
      );
    }
    if (hasResico) {
      return !!formik.values.balance_resico && formik.isValid;
    }
    if (hasDeclaration) {
      return !!formik.values.balance && formik.isValid;
    }
    return false;
  }, [
    formik.isValid,
    formik.values.balance,
    formik.values.balance_resico,
    hasDeclaration,
    hasResico,
  ]);

  const differredPaymentRows = useMemo(() => {
    // Get the selected number of installments from the form
    let selectedInstallments = 6; // Default to 6
    if (formik.values.paymentPreference) {
      // Extract number from string like "2_month_payment"
      const match = formik.values.paymentPreference.match(
        /^(\d+)_month_payment$/,
      );
      if (match) {
        selectedInstallments = parseInt(match[1], 10);
      }
    }

    return calculateDifferredPaymentRows({
      creditBalance,
      period: currentDeclaration?.period,
      selectedInstallments,
    });
  }, [
    creditBalance,
    currentDeclaration?.period,
    formik.values.paymentPreference,
  ]);

  const enableDifferredPaymentByDate = useMemo(() => {
    // should be enable only if the server date is before april 30th before 10:00 am of current year
    if (!serverDateValue) return false;

    const serverDate = dayjs(serverDateValue);
    const currentYear = serverDate.year();

    const SERVER_TIME_LAG = 121;

    // Create the deadline date (April 30th at 10:00 AM)
    // Adjusting by subtracting 121 minutes to compensate for server time lag
    const deadlineDate = dayjs(`${currentYear}-04-30T10:00:00`).subtract(
      SERVER_TIME_LAG,
      'minute',
    );

    return serverDate.isBefore(deadlineDate);
  }, [serverDateValue]);

  return {
    currentDeclaration,
    hasError,
    payload,
    setPayload,
    process,
    isLoading,
    formatBalance,
    formatBalanceResico,
    textForBalance,
    textForBalanceResico,
    enabledNextBtn,
    formik,
    balanceInFavor,
    balanceInFavorResico,
    creditBalanceResico,
    extraPayload,
    hasResico,
    hasDeclaration,
    isEnabledPaymentInInstallments,
    differredPaymentRows,
    serverDate: serverDateValue,
    enableDifferredPaymentByDate,
  };
}
