import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CaptureLineDocument,
  Workflow,
  WorkflowPhaseKeyDict,
} from 'types/entities';
import useHasPayrollRetention from '@hooks/useHasPayrollRetention';
import getCurrentRouteByWorkflowPhase from '@util/getCurrentRouteByWorkflowPhase';
import getDocumentFromDeclaration from '@util/getDocumentFromDeclaration';
import dayjs from 'dayjs';
import getRegimeNameFromFiscalRegime from 'shared/util/getRegimeNameFromFiscalRegime';

const SPECIAL_USER_IDS = [6383];
const FISCAL_REGIME_ID = 4;
const SPECIAL_REGIME_WORKFLOWS = [625, 612];
const unreadyWorkflowStatus = [
  'fetching_invoice',
  'classifying',
  'verify_declaration',
  'submit_declaration',
  'done',
];

export default function useWorkflowStatus(
  workflow: Workflow,
  selectedMonth: string,
  availableWorkflow: Workflow | null,
) {
  const { hasPayrollRetentions } = useHasPayrollRetention();
  const navigate = useNavigate();

  const declarations = useMemo(
    () => workflow.declarations.filter((declaration) => declaration.is_active),
    [workflow],
  );

  const isNewWorkflow =
    declarations?.length === 2 &&
    declarations?.every((declaration) =>
      SPECIAL_REGIME_WORKFLOWS.includes(declaration.fiscal_regime.sat_key),
    );

  const captureLineDocuments = useMemo(() => {
    const documents = declarations.map((declaration) =>
      getDocumentFromDeclaration(declaration),
    );
    if (hasPayrollRetentions && declarations.length > 0) {
      documents.push(getDocumentFromDeclaration(declarations[0], true));
    }

    return documents.filter(Boolean) as CaptureLineDocument[];
  }, [declarations, hasPayrollRetentions]);

  const declarationDocuments = useMemo(() => {
    const documents = declarations.map((declaration) => {
      if (!declaration?.pdf_link) {
        return null;
      }
      return {
        url: declaration.pdf_link,
        regimeName: getRegimeNameFromFiscalRegime(declaration.fiscal_regime),
      };
    });
    return documents.filter(Boolean) as {
      url: string;
      regimeName: string;
    }[];
  }, [declarations]);

  const hasBeforeMonthBypass = useMemo(() => {
    if (!workflow?.declarations) {
      return false;
    }

    const declaration = workflow.active_declaration;
    const fiscalRegimeId = declaration?.fiscal_regime_id;
    const isSpecialUser = SPECIAL_USER_IDS.includes(workflow.taxable_entity_id);

    // if declaration year is current year pass
    if (
      !!declaration?.start_date &&
      dayjs(declaration?.start_date).year() === dayjs().year()
    ) {
      return true;
    }

    if (isSpecialUser) {
      const declarationMonth = dayjs(declaration?.start_date).month() + 1;
      const currentMonth = dayjs().month();
      return (
        fiscalRegimeId === FISCAL_REGIME_ID && declarationMonth <= currentMonth
      );
    }

    const currentMonth = dayjs(declaration?.start_date).month() + 1;
    return (
      fiscalRegimeId === FISCAL_REGIME_ID && currentMonth === dayjs().month()
    );
  }, [workflow]);
  const currentDate = dayjs().format('YYYY-MM-DD');
  const isSameMonth = dayjs(selectedMonth).isSame(currentDate, 'month');
  // TODO this will be removed in the future
  const lockedDeclaration = useMemo(
    () =>
      availableWorkflow &&
      availableWorkflow.id !== workflow.id &&
      workflow.status !== 'done' &&
      !hasBeforeMonthBypass &&
      !isSameMonth,
    [availableWorkflow, workflow, hasBeforeMonthBypass, isSameMonth],
  );

  const isEnabledBtn = useMemo(
    () => !unreadyWorkflowStatus.includes(workflow.status),
    [workflow],
  );

  const openWorkflow = () => {
    if (!isNewWorkflow) {
      navigate(`/app/workflow/${workflow.id}/default`);
      return;
    }
    const route = getCurrentRouteByWorkflowPhase(
      WorkflowPhaseKeyDict.Intro,
      workflow,
    );
    if (route) {
      navigate(route);
    }
  };

  return {
    captureLineDocuments,
    isEnabledBtn,
    workflowStatus: workflow.status,
    openWorkflow,
    isNewWorkflow,
    declarationDocuments,
  };
}
