import * as React from 'react';
import {
  getCellProperty,
  keyCodes,
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  inNumericKey,
  isNavigationKey,
  isNumpadNumericKey,
  isAllowedOnNumberTypingKey,
  isCharAllowedOnNumberInput,
  getCharFromKey,
} from '@silevis/reactgrid';
import { Flex } from '@konta/ui';

export interface NumberWithIconCell extends Cell {
  type: 'numberWithIcon';
  value: number;
  icon: React.ReactNode;
  format?: Intl.NumberFormat;
  validator?: (value: number) => boolean;
  nanToZero?: boolean;
  hideZero?: boolean;
  errorMessage?: string;
  justifyContent?: 'start' | 'center' | 'end' | 'between';
  iconFirst?: boolean;
}

export class NumberWithIconCellTemplate
  implements CellTemplate<NumberWithIconCell>
{
  private wasEscKeyPressed = false;

  getCompatibleCell(
    uncertainCell: Uncertain<NumberWithIconCell>,
  ): Compatible<NumberWithIconCell> {
    let value: number;
    try {
      value = getCellProperty(uncertainCell, 'value', 'number');
    } catch (error) {
      value = NaN;
    }
    const numberFormat =
      uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) ||
      (uncertainCell.hideZero && displayValue === 0)
        ? ''
        : numberFormat.format(displayValue);
    return {
      ...uncertainCell,
      value: displayValue,
      text,
      icon: uncertainCell.icon,
    };
  }

  handleKeyDown(
    cell: Compatible<NumberWithIconCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string,
  ): { cell: Compatible<NumberWithIconCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = getCharFromKey(key);
    if (!ctrl && isCharAllowedOnNumberInput(char)) {
      const value = Number(char);

      if (Number.isNaN(value) && isCharAllowedOnNumberInput(char))
        return {
          cell: { ...this.getCompatibleCell({ ...cell, value }), text: char },
          enableEditMode: true,
        };
      return {
        cell: this.getCompatibleCell({ ...cell, value }),
        enableEditMode: true,
      };
    }
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<NumberWithIconCell>,
    cellToMerge: UncertainCompatible<NumberWithIconCell>,
  ): Compatible<NumberWithIconCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return '-';
      case keyCodes.COMMA:
        return ',';
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return '.';
      default:
        return cellText;
    }
  };

  getClassName(
    cell: Compatible<NumberWithIconCell>,
    isInEditMode: boolean,
  ): string {
    const isValid = cell.validator?.(cell.value) ?? true;
    const className = cell.className || '';
    return `${!isValid ? 'rg-invalid' : ''} ${className}`;
  }

  render(
    cell: Compatible<NumberWithIconCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<NumberWithIconCell>,
      commit: boolean,
    ) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      const isValid = cell.validator?.(cell.value) ?? true;
      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cell.text;
      return (
        <Flex
          justify={cell.justifyContent || 'between'}
          css={{ flex: 1, gap: '8px' }}
        >
          {cell.iconFirst ? cell.icon : textToDisplay}
          {cell.iconFirst ? textToDisplay : cell.icon}
        </Flex>
      );
    }

    const locale = cell.format
      ? cell.format.resolvedOptions().locale
      : window.navigator.languages[0];
    const format = new Intl.NumberFormat(locale, {
      useGrouping: false,
      maximumFractionDigits: 20,
    });

    return (
      <input
        inputMode="decimal"
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={
          Number.isNaN(cell.value)
            ? this.getTextFromCharCode(cell.text)
            : format.format(cell.value)
        }
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(e.currentTarget.value.replace(/,/g, '.')),
            }),
            false,
          )
        }
        onBlur={(e) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(e.currentTarget.value.replace(/,/g, '.')),
            }),
            !this.wasEscKeyPressed,
          );
          this.wasEscKeyPressed = false;
        }}
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode) ||
            ((e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_A)
          )
            e.stopPropagation();
          if (
            !inNumericKey(e.keyCode) &&
            !isNavigationKey(e.keyCode) &&
            !isCharAllowedOnNumberInput(getCharFromKey(e.key))
          )
            e.preventDefault();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    );
  }
}
