import React, { useEffect, useState } from 'react';
import { Alert, Button, CircularLoading, Flex, Spacer, Text } from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { useToggle } from 'usehooks-ts';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import ProcessStepLayout from '@components/ProcessStepLayout';
import FormikTextInput from '@components/FormikTextInput';
import useUpdateTaxableEntityMutation from '@hooks/useUpdateTaxableEntityMutation';
import getErrorMessage from '@util/getErrorMessage';
import { useTaxableEntity } from '@hooks';
import { NotificationManager } from '@components/Notifications';
import useProcess from '@hooks/useProcess';
import { ProcessType } from 'types/entities.ts';
import ProcessStepDescription from '@components/ProcessStepDescription';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepHelpFeature from '@components/ProcessStepHelpFeature';
import OnboardingCiecHelp from './OnboardingCIECHelp.tsx';
import OnboardingCiecRequest from './OnboardingCiecRequest.tsx';

const process = ProcessType.OnboardingOnboardingProcess;
const validationSchema = yup.object().shape({
  rfc: yup.string().rfc().required('Campo requerido'),
  ciec: yup.string().ciec().required('Campo requerido'),
});

export default function OnboardingCIEC() {
  const [refetchInterval, setRefetchInterval] = useState(0);
  const { activeStep, next } = useProcess(process, {
    refetchInterval,
  });

  const [showHelpForCiec, setShowHelpForCiec] = useToggle(false);
  const [showHelpForRequestCiec, setShowHelpForRequestCiec] = useToggle(false);
  const [hasFiel, setHasFiel] = useToggle(false);
  const status = activeStep?.answers.ciec;
  const isCIECInvalid = status === 'invalid' || status === 'error';
  const isCIECPending = status === 'pending';
  const enabledForm = !isCIECPending || isCIECInvalid;

  const updateTaxableEntity = useUpdateTaxableEntityMutation();
  const { taxable_entity: taxableEntity } = useTaxableEntity();

  const uploadCiec = async (payload) => {
    try {
      await next({
        steps_attributes: [
          {
            id: activeStep.id,
            answers: {
              ciec: 'pending',
              ...(hasFiel && { fiel: true }),
            },
          },
        ],
      });
      await updateTaxableEntity.mutateAsync(payload);
      NotificationManager.success(
        'Se ha subido la CIEC correctamente',
        'Credenciales',
      );
    } catch (e) {
      await next({
        steps_attributes: [
          {
            id: activeStep.id,
            answers: {
              ciec: 'invalid',
              ...(hasFiel && { fiel: true }),
            },
          },
        ],
      });
      let errorMessage = getErrorMessage(e);
      if (errorMessage === 'ya ha sido tomado') {
        errorMessage = 'Ya existe un cliente con este RFC';
      }
      NotificationManager.error(errorMessage, 'Credenciales');
    }
  };
  // cancel re-fetch if status is invalid
  useEffect(() => {
    if (isCIECInvalid) {
      setRefetchInterval(0);
    }
  }, [isCIECInvalid]);
  // enabled re-fetch if status is pending
  useEffect(() => {
    if (isCIECPending) {
      setRefetchInterval(10000);
    }
  }, [isCIECPending]);

  const initialValues = {
    rfc: taxableEntity?.rfc || '',
    ciec: '',
  };

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      uploadCiec({ ...values, ciec: values.ciec.slice(0, 8), has_ciec: true }),
  });

  const alertCss = { maxWidth: '670px', flex: 1 };

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Apertura de cuenta"
      titleChip="En curso"
      content={
        // eslint-disable-next-line no-nested-ternary
        showHelpForCiec ? (
          <OnboardingCiecHelp setShowHelpForCiec={setShowHelpForCiec} />
        ) : showHelpForRequestCiec ? (
          <OnboardingCiecRequest
            setShowHelpForRequestCiec={setShowHelpForRequestCiec}
          />
        ) : (
          <FormikProvider value={formik}>
            <Flex direction="column" gap={12}>
              <ProcessStepTitle>Sube tus credenciales</ProcessStepTitle>
              <ProcessStepDescription>
                Konta necesita tus credenciales del SAT para poder descargar
                automáticamente tus facturas, revisar si recibes notificaciones
                en tu buzón tributario y poder declarar tus declaraciones de
                manera automática.
              </ProcessStepDescription>
            </Flex>
            <Flex direction="column" css={{ maxWidth: '400px' }} gap={24}>
              <FormikTextInput
                name="rfc"
                data-cy="onboardingCiecRfcInput"
                placeholder="XAXX010101000"
                label="RFC"
                disabled={!enabledForm}
                onChange={(e) =>
                  formik.setFieldValue('rfc', e.target.value.toUpperCase())
                }
              />
              <FormikTextInput
                label="Agregar mi CIEC"
                type="password"
                name="ciec"
                data-cy="onboardingCiecCiecInput"
                placeholder="*****"
                disabled={!enabledForm}
              />
            </Flex>
            {isCIECPending && (
              <Flex>
                <Alert color="primary" css={alertCss}>
                  La verificación de la CIEC está en proceso{' '}
                  <CircularLoading s />
                  <Spacer />
                  Este proceso puede tomar aproximadamente 2 minutos, por favor
                  no cierre el navegador.
                  <Spacer />
                  Una vez finalizado, será redirigido al siguiente paso..
                </Alert>
              </Flex>
            )}
            {isCIECInvalid && (
              <Flex>
                <Alert color="error" css={alertCss}>
                  La CIEC ó RFC ingresados no son validos.
                  <Spacer />
                  Por favor ingresalos nuevamente.
                </Alert>
              </Flex>
            )}

            {enabledForm && (
              <Flex gap={12}>
                <Button
                  color="primary600"
                  rightIcon={<ArrowRightIcon />}
                  onClick={formik.submitForm}
                  loading={updateTaxableEntity.isLoading}
                  data-cy="onboardingCiecNextBtn"
                >
                  Validar CIEC
                </Button>

                <Button
                  color="gray"
                  variant="outlined"
                  onClick={setShowHelpForCiec}
                >
                  No tengo mi CIEC
                </Button>
                <Button
                  color="gray"
                  variant="outlined"
                  onClick={() => {
                    setShowHelpForRequestCiec();
                    setHasFiel();
                  }}
                >
                  Tengo FIEL pero no CIEC
                </Button>
              </Flex>
            )}
          </FormikProvider>
        )
      }
      rightSidebarContent={
        <>
          <ProcessStepHelpFeature
            title="¿Qué es la CIEC?"
            description={
              <>
                {
                  'La CIEC es la contraseña que utilizas para entrar al portal del SAT para realizar trámites. Más información sobre la CIEC en '
                }
                <Text
                  as="a"
                  color="primary700"
                  href="https://www.youtube.com/watch?v=myNMhcucWNo"
                  target="_blank"
                >
                  este vídeo.
                </Text>
              </>
            }
          />
          <ProcessStepHelpFeature
            title="¿Cómo sacar mi CIEC?"
            description={
              <>
                {
                  'Es posible solicitar la CIEC en el portal del SAT sin necesidad de acudir personalmente a una oficina. '
                }
                <Text
                  as="a"
                  color="primary700"
                  href="https://www.youtube.com/watch?v=myNMhcucWNo"
                  target="_blank"
                >
                  Esta guía
                </Text>
                {
                  ' te puede ayudar con el proceso. Si prefieres ver un video, también tienes esa opción.'
                }
              </>
            }
          />
        </>
      }
    />
  );
}
