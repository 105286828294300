import { ReactNode, useMemo, useState } from 'react';
import { useMediaQuery } from '@konta/ui';
import useSubscriptions from '@hooks/useSubscriptions';
import subscriptionService from 'shared/services/subscription';
import type { Plan as PlanData } from '@konta/ui/dist/components/Plans/types';
import { BillingPeriod, Plan, PricesData } from 'types/subscription';
import { FeatureSection } from './types';

const FUNCTIONALITIES: FeatureSection = {
  title: 'Funcionalidades',
  id: 'functionalities',
  features: [
    {
      title: 'Equipo de atención para resolver dudas',
      value: true,
      type: 'feature',
    },
    {
      title:
        'Aclaraciones necesarias para la aprobación y presentación ante el SAT.',
      value: true,
      type: 'feature',
    },
    { title: 'Diagnóstico fiscal gratis', value: true, type: 'feature' },
    { title: 'Facturación ilimitada', value: true, type: 'feature' },
    { title: 'Movimientos ilimitados', value: true, type: 'feature' },
    { title: 'Konta Estatus', value: true, type: 'feature' },
  ],
};

const createFeatures = (
  billingPeriodicity: string,
  planPrices: PricesData,
  badgeText: string,
  [customPlan0, customPlan1]: boolean[],
  withDetail = true,
  planType?: 'assisted' | 'personalized' | 'fiscal-visor',
): FeatureSection[] => [
  ...(withDetail
    ? [
        {
          title: `Detalle de ${billingPeriodicity}`,
          id: 'periodDetail',
          features: [
            {
              title: `Costo base mensual`,
              value: `$${planPrices.price} + IVA`,
              type: 'feature',
            },
            {
              title: '+1 Régimen',
              value:
                planType === 'personalized'
                  ? 'Incluido'
                  : `$${planPrices.extraRegimen}c/u ${badgeText}`,
              type: 'feature',
            },
          ],
        },
      ]
    : []),
  {
    ...FUNCTIONALITIES,
    features: [
      {
        title: 'Asesor personalizado',
        value: customPlan0,
        type: 'feature',
      },
      {
        title:
          'Conciliación de tu estado de cuenta con las facturas emitidas y presentación de tus impuestos.',
        value: customPlan1,
        type: 'feature',
      },
      ...FUNCTIONALITIES.features,
    ],
  },
];

const createFiscalVisorFeatures = () => [
  {
    title: `Funcionalidades`,
    id: 'functionalities',
    features: [
      {
        title: 'Opinión de cumplimiento y calificación ante el SAT',
        value: true,
        type: 'feature',
      },
      {
        title: 'Recomendaciones personalizadas',
        value: true,
        type: 'feature',
      },
      {
        title: 'Información detallada sobre tus deducciones personales',
        value: true,
        type: 'feature',
      },
      {
        title: 'Gráficas de ingresos y gastos acumulados',
        value: true,
        type: 'feature',
      },
    ],
  },
];

const createPlan = (
  id: Plan,
  name: string,
  supportingText: string,
  billingPeriod: BillingPeriod,
  planPrices: PricesData,
  badgeText: string,
  features: FeatureSection[],
  informationTexts: string[],
  mobileFeatures: (string | ReactNode)[],
  planFeatureDescription?: string,
  isMostPopular = false,
  discountType?: 'percentage' | 'months',
  btnText?: string,
): PlanData => ({
  textBeforePrice: 'Desde',
  id,
  name,
  badgeText,
  supportingText,
  billingPeriod,
  pricing: {
    price: planPrices.price,
    discount: planPrices.discount,
  },
  isCurrentPlan: false,
  isMostPopular,
  mobileFeatures,
  features,
  informationTexts,
  planFeatureDescription,
  discountType,
  btnText,
});

const getMobileFeatures = (featuresArray: FeatureSection[]) =>
  featuresArray
    .filter(({ id }) => id === 'functionalities')
    .flatMap(({ features }) =>
      features.filter(({ value }) => Boolean(value)).map(({ title }) => title),
    );

const {
  BADGE_TEXT,
  BILLING_PERIODICITY_TEXT,
  INFORMATION_TEXTS,
  PLAN_PRICES,
  FISCAL_VISOR_NAMES_PLANS,
  GENERAL_NAMES_PLANS,
  PLANS_WITH_BILLING_PERIOD,
} = subscriptionService.constants;

interface UsePricingProps {
  source: 'fiscal-visor' | 'app';
}

export default function usePricing({ source }: UsePricingProps) {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('monthly');
  const isSmall = useMediaQuery('(max-width: 1020px)');
  const isTablet = useMediaQuery('(max-width: 768px)');

  const { handlePlanSelect } = useSubscriptions() as {
    handlePlanSelect: (
      planId: string,
      plans: PlanData[],
      billingPeriod: string,
    ) => void;
  };

  const plans = useMemo<PlanData[]>(() => {
    const billingPeriodicity = BILLING_PERIODICITY_TEXT[billingPeriod];
    const badgeText = BADGE_TEXT.monthly;
    const assistedFeatures = createFeatures(
      billingPeriodicity,
      PLAN_PRICES.assisted_plan[billingPeriod],
      badgeText,
      [false, false],
    );
    const customFeatures = createFeatures(
      billingPeriodicity,
      PLAN_PRICES.personalized_plan[billingPeriod],
      badgeText,
      [true, true],
      true,
      'personalized',
    );
    const fiscalVisorFeatures = createFiscalVisorFeatures();

    const assistedMobileFeatures = getMobileFeatures(assistedFeatures);
    const customMobileFeatures = getMobileFeatures(customFeatures);
    const fiscalVisorMobileFeatures = getMobileFeatures(fiscalVisorFeatures);
    const diffBetweenMobileFeatures = customMobileFeatures.filter(
      (feature) => !assistedMobileFeatures.includes(feature),
    );
    return [
      createPlan(
        'assisted_plan',
        'Asistido',
        'Un experto te ayudará a hacer tus impuestos',
        billingPeriod,
        PLAN_PRICES.assisted_plan[billingPeriod],
        badgeText,
        assistedFeatures,
        INFORMATION_TEXTS.assisted_plan[billingPeriod],
        assistedMobileFeatures,
      ),
      createPlan(
        'personalized_plan',
        'Personalizado',
        'Nosotros hacemos los impuestos por ti',
        billingPeriod,
        PLAN_PRICES.personalized_plan[billingPeriod],
        badgeText,
        customFeatures,
        INFORMATION_TEXTS.personalized_plan[billingPeriod],
        [
          <b key="allPlanBasic">Todo lo del plan Asistido +</b>,
          ...diffBetweenMobileFeatures,
        ],
      ),
      createPlan(
        'fiscal_visor_plan',
        '"Visor Fiscal"',
        'Conoce el detalle de tu situación fiscal en tiempo real.',
        billingPeriod,
        {
          price: PLAN_PRICES.fiscal_visor_plan[billingPeriod].price,
          discount: 2,
          extraRegimen: 0,
        },
        badgeText,
        fiscalVisorFeatures,
        [],
        fiscalVisorMobileFeatures,
        'Podrás monitorear, consultar y gestionar de manera práctica una descripción completa de tus obligaciones ante el SAT.',
        false,
        'months',
        'Activar plan (2 meses gratis)',
      ),
    ].filter((plan) =>
      source === 'fiscal-visor'
        ? FISCAL_VISOR_NAMES_PLANS.includes(plan.id)
        : GENERAL_NAMES_PLANS.includes(plan.id),
    );
  }, [billingPeriod, source]);

  const showBillingPeriodicity = useMemo(
    () => plans.every((plan) => PLANS_WITH_BILLING_PERIOD.includes(plan.id)),
    [plans],
  );

  return {
    billingPeriod,
    setBillingPeriod,
    isSmall,
    isTablet,
    plans,
    handlePlanSelect,
    showBillingPeriodicity,
  };
}
