import { Button, Flex } from '@konta/ui';
import AnnualDeclarationGrid from 'shared/components/AnnualDeclarationGrid';
import type { AnnualDeclaration, Workflow } from 'types/entities';
import Legends from '@routes/app/AnnualDeclarations/AnnualDeclaration/Legends';
import NextStepButton from '@components/NextStepButton';
import useResumeByRegime from './useResumeByRegime';
import { Root } from './styled';
import { SubTitle, Title, TitleContainer } from '../styled';
import PersonalDeductionModal from './PersonalDeductionModal/PersonalDeductionModal';
import RequestAdjustmentModal from '../RequestAdjustmentModal/RequestAdjustmentModal';

interface ResumeByRegimeProps {
  workflows: Workflow[];
  satKeys: number[];
  onHandleEntriesView: (monthIdx: string, type: 'income' | 'expense') => void;
  period: string | number;
  selectedAnnualDeclaration: AnnualDeclaration | null;
  preferredFiscalRegime?: number | null;
  openPersonalDeductionModal: boolean;
  toggleOpenPersonalDeductionModal: () => void;
}
export default function ResumeByRegime({
  workflows,
  satKeys,
  onHandleEntriesView,
  period,
  selectedAnnualDeclaration,
  preferredFiscalRegime,
  openPersonalDeductionModal,
  toggleOpenPersonalDeductionModal,
}: ResumeByRegimeProps) {
  const {
    title,
    filteredSatKeys,
    payload,
    setPayload,
    openDisagreeModal,
    toggleDisagreeModal,
    hasAnswers,
    process,
  } = useResumeByRegime({
    satKeys,
    preferredFiscalRegime,
  });
  return (
    <>
      {openPersonalDeductionModal && (
        <PersonalDeductionModal
          openPersonalDeductionModal={openPersonalDeductionModal}
          toggleOpenPersonalDeductionModal={toggleOpenPersonalDeductionModal}
          periodDate={period as string}
        />
      )}
      <RequestAdjustmentModal
        isOpen={openDisagreeModal}
        toggleIsOpen={toggleDisagreeModal}
      />
      <Root>
        <TitleContainer>
          <Title>
            Resultado estimado de tu declaración anual {title} {period}
          </Title>
          <SubTitle>
            Esto es el detalle de tus impuestos durante el año
          </SubTitle>
        </TitleContainer>
        <Flex column css={{ flex: 1 }}>
          <Legends />
          <AnnualDeclarationGrid
            onHandleEntriesView={onHandleEntriesView}
            toggleOpenPersonalDeductionModal={toggleOpenPersonalDeductionModal}
            satKeys={filteredSatKeys}
            workflows={workflows}
            period={period}
            selectedAnnualDeclaration={selectedAnnualDeclaration}
            title={title}
          />
        </Flex>
      </Root>
      <Flex justify="end" gap={12}>
        <Button
          color="gray"
          variant="outlined"
          disabled={hasAnswers}
          onClick={toggleDisagreeModal}
        >
          {hasAnswers ? 'Notificación enviada' : 'Solicitar un ajuste'}
        </Button>
        <NextStepButton
          currentProcessType={process}
          data-cy="resumeAnnualNextBtn"
          text="Continuar"
          payload={payload}
          onClick={() => setPayload(true)}
        />
      </Flex>
    </>
  );
}
