/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import { useEffect, useMemo } from 'react';
import { InformativeDataAnnualStep, ProcessType } from 'types/entities';
import * as validation from '@util/validation';
import { useFormik } from 'formik';
import useProcess from './useProcess';

const amountValidation = yup.number().min(1).required();

// Define the dividend schema separately for reuse
const dividendSchema = yup.object({
  corporate_rfc: yup
    .string()
    .required('RFC de la sociedad es requerido')
    .test(
      'format',
      'Porfavor ingrese un RFC con formato valido. Ejemplo: XAX010101000',
      (rfc) => validation.rfc(rfc || ''),
    ),
  dividend_or_profit_amount: yup
    .number()
    .required('Monto del dividendo es requerido'),
  corporate_isr_tax_paid: yup.number().required('ISR pagado es requerido'),
});

const validationSchema = yup.object({
  has_vehicle_sale: yup.boolean(),
  vehicle_sale_amount: yup
    .number()
    .when('has_vehicle_sale', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  vehicle_sale_date: yup
    .mixed()
    .nullable()
    .when('has_vehicle_sale', {
      is: true,
      then: yup.string().nullable().required(),
    })
    .default(null),
  vehicle_purchased_amount: yup
    .mixed()
    .when('has_vehicle_sale', {
      is: true,
      then: amountValidation,
    })
    .default(false),
  vehicle_purchased_date: yup
    .mixed()
    .nullable()
    .when('has_vehicle_sale', {
      is: true,
      then: yup.string().nullable().required(),
    })
    .default(null),
  has_real_state_sale: yup.boolean(),
  real_state_sale_amount: yup
    .number()
    .when('has_real_state_sale', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_loans: yup.boolean(),
  loans_amount: yup
    .number()
    .when('has_loans', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_donations: yup.boolean(),
  donations_amount: yup
    .number()
    .when('has_donations', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_governmental_support: yup.boolean(),
  governmental_support_amount: yup
    .number()
    .when('has_governmental_support', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_rewards: yup.boolean(),
  rewards: yup
    .number()
    .when('has_rewards', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_inheritance: yup.boolean(),
  inheritance: yup
    .number()
    .when('has_inheritance', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  has_charged_viaticals: yup.boolean(),
  charged_viaticals: yup
    .number()
    .when('has_charged_viaticals', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  /*  */
  has_foreign_income_or_pending_taxes: yup.boolean(),
  income_received: yup
    .mixed()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: amountValidation,
    })
    .default(null),
  foreign_tax_paid: yup
    .mixed()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: amountValidation,
    })
    .default(null),
  foreign_tax_paid_dividends: yup
    .mixed()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: amountValidation,
    })
    .default(null),
  foreign_tax_credit: yup
    .mixed()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: amountValidation,
    })
    .default(null),
  total_accumulable_income: yup
    .mixed()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: amountValidation,
    })
    .default(0),
  taxpayer_id_foreign_country: yup
    .mixed()
    .nullable()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: yup.string().nullable().required(),
    })
    .default(null),
  country_income_obtained: yup
    .mixed()
    .nullable()
    .when('has_foreign_income_or_pending_taxes', {
      is: true,
      then: yup.string().nullable().required(),
    })
    .default(null),
  has_dividends: yup.boolean(),
  dividends: yup
    .array()
    .of(dividendSchema)
    .when('has_dividends', {
      is: true,
      then: yup
        .array()
        .of(dividendSchema)
        .min(1, 'Debes agregar al menos un dividendo'),
    }),
});

// Define a single empty dividend object for reuse
const emptyDividend = {
  corporate_rfc: '',
  dividend_or_profit_amount: 0,
  corporate_isr_tax_paid: 0,
};

const defaultInitialValues = {
  has_vehicle_sale: false,
  vehicle_sale_amount: 0,
  vehicle_sale_date: null,
  vehicle_purchased_amount: 0,
  vehicle_purchased_date: null,
  has_real_state_sale: false,
  real_state_sale_amount: 0,
  has_loans: false,
  loans_amount: 0,
  has_donations: false,
  donations_amount: 0,
  has_governmental_support: false,
  governmental_support_amount: 0,
  has_rewards: false,
  rewards: 0,
  has_inheritance: false,
  inheritance: 0,
  has_charged_viaticals: false,
  charged_viaticals: 0,
  has_foreign_income_or_pending_taxes: false,
  income_received: null,
  foreign_tax_paid: null,
  foreign_tax_paid_dividends: null,
  foreign_tax_credit: null,
  total_accumulable_income: null,
  taxpayer_id_foreign_country: null,
  country_income_obtained: null,
  has_dividends: false,
  dividends: [],
};

export type InformativeAnnualPayload = yup.InferType<typeof validationSchema>;
export type DividendItem = yup.InferType<typeof dividendSchema>;

function useInitialValues(): InformativeAnnualPayload {
  const { activeStep } = useProcess(ProcessType.AnnualDeclarationProcess);

  return useMemo(() => {
    const informativeDataAnualStep = activeStep as InformativeDataAnnualStep;

    if (
      !informativeDataAnualStep?.answers ||
      typeof informativeDataAnualStep?.answers === 'string'
    ) {
      return defaultInitialValues;
    }
    const {
      vehicle_sale_amount,
      vehicle_purchased_amount,
      vehicle_purchased_date,
      governmental_support_amount,
      real_state_sale_amount,
      vehicle_sale_date,
      loans_amount,
      donations_amount,
      rewards,
      inheritance,
      charged_viaticals,
      foreign_income_or_pending_taxes,
      income_received,
      foreign_tax_paid,
      foreign_tax_paid_dividends,
      foreign_tax_credit,
      total_accumulable_income,
      taxpayer_id_foreign_country,
      country_income_obtained,
      corporate_rfc,
      dividend_or_profit_amount,
      corporate_isr_tax_paid,
      dividends,
      dividends_details,
    } = informativeDataAnualStep.answers as InformativeDataAnnualStep['answers'] & {
      dividends_details?: Array<{
        corporate_rfc: string;
        dividend_or_profit_amount: number;
        corporate_isr_tax_paid: number;
      }>;
    };

    const hasVehicleSale =
      vehicle_sale_amount > 0 ||
      (vehicle_purchased_amount && vehicle_purchased_amount > 0) ||
      false;
    const hasRealStateSale = real_state_sale_amount > 0;
    const hasLoans = loans_amount > 0;
    const hasDonations = donations_amount > 0;
    const hasGovernmentalSupport = governmental_support_amount > 0;
    const hasRewards = rewards > 0;
    const hasInheritance = inheritance > 0;
    const hasChargedViaticals = charged_viaticals > 0;
    const hasForeignIncomeOrPendingTaxes =
      foreign_income_or_pending_taxes === 'Si' || false;

    const hasDividends = dividends === 'Si';

    // Initialize dividends array with explicit typing
    let dividendsArray: Array<{
      corporate_rfc: string;
      dividend_or_profit_amount: number;
      corporate_isr_tax_paid: number;
    }> = [];

    // First, check if dividends_details exists and use it
    if (
      hasDividends &&
      dividends_details &&
      Array.isArray(dividends_details) &&
      dividends_details.length > 0
    ) {
      dividendsArray = dividends_details.map((item) => ({
        corporate_rfc: item.corporate_rfc || '',
        dividend_or_profit_amount: item.dividend_or_profit_amount || 0,
        corporate_isr_tax_paid: item.corporate_isr_tax_paid || 0,
      }));
    }
    // Fall back to legacy single dividend if no details array but single values exist
    else if (
      hasDividends &&
      corporate_rfc !== null &&
      dividend_or_profit_amount > 0
    ) {
      dividendsArray = [
        {
          corporate_rfc,
          dividend_or_profit_amount,
          corporate_isr_tax_paid,
        },
      ];
    }

    return {
      has_vehicle_sale: hasVehicleSale,
      vehicle_sale_amount,
      vehicle_sale_date,
      vehicle_purchased_amount,
      vehicle_purchased_date,
      has_real_state_sale: hasRealStateSale,
      real_state_sale_amount,
      has_loans: hasLoans,
      loans_amount,
      has_donations: hasDonations,
      donations_amount,
      has_governmental_support: hasGovernmentalSupport,
      governmental_support_amount,
      has_rewards: hasRewards,
      rewards,
      has_inheritance: hasInheritance,
      inheritance,
      has_charged_viaticals: hasChargedViaticals,
      charged_viaticals,
      has_foreign_income_or_pending_taxes: hasForeignIncomeOrPendingTaxes,
      income_received,
      foreign_tax_paid,
      foreign_tax_paid_dividends,
      foreign_tax_credit,
      total_accumulable_income,
      taxpayer_id_foreign_country,
      country_income_obtained,
      has_dividends: hasDividends,
      dividends: dividendsArray,
    };
  }, [activeStep]);
}

interface InformativeAnnualFormProps {
  onSubmitForm: (payload: InformativeAnnualPayload) => void;
}
export default function useInformativeAnnualForm({
  onSubmitForm,
}: InformativeAnnualFormProps) {
  const initialValues = useInitialValues();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
  });

  const {
    has_vehicle_sale,
    has_donations,
    has_loans,
    has_real_state_sale,
    has_governmental_support,
    has_rewards,
    has_inheritance,
    has_charged_viaticals,
    has_foreign_income_or_pending_taxes,
    has_dividends,
  } = formik.values;

  useEffect(() => {
    if (!has_vehicle_sale) {
      void formik.setFieldValue('vehicle_sale_date', null);
      void formik.setFieldValue('vehicle_sale_amount', 0);
      void formik.setFieldValue('vehicle_purchased_date', null);
      void formik.setFieldValue('vehicle_purchased_amount', 0);
    }
  }, [has_vehicle_sale]);

  useEffect(() => {
    if (!has_real_state_sale) {
      void formik.setFieldValue('real_state_sale_amount', 0);
    }
  }, [has_real_state_sale]);
  useEffect(() => {
    if (!has_loans) {
      void formik.setFieldValue('loans_amount', 0);
    }
  }, [has_loans]);
  useEffect(() => {
    if (!has_donations) {
      void formik.setFieldValue('donations_amount', 0);
    }
  }, [has_donations]);
  useEffect(() => {
    if (!has_governmental_support) {
      void formik.setFieldValue('governmental_support_amount', 0);
    }
  }, [has_governmental_support]);
  useEffect(() => {
    if (!has_rewards) {
      void formik.setFieldValue('rewards', 0);
    }
  }, [has_rewards]);
  useEffect(() => {
    if (!has_inheritance) {
      void formik.setFieldValue('inheritance', 0);
    }
  }, [has_inheritance]);
  useEffect(() => {
    if (!has_charged_viaticals) {
      void formik.setFieldValue('charged_viaticals', 0);
    }
  }, [has_charged_viaticals]);

  useEffect(() => {
    if (!has_foreign_income_or_pending_taxes) {
      void formik.setFieldValue('income_received', 0);
      void formik.setFieldValue('foreign_tax_paid', 0);
      void formik.setFieldValue('foreign_tax_paid_dividends', 0);
      void formik.setFieldValue('foreign_tax_credit', 0);
      void formik.setFieldValue('total_accumulable_income', 0);
      void formik.setFieldValue('taxpayer_id_foreign_country', null);
      void formik.setFieldValue('country_income_obtained', null);
    }
  }, [has_foreign_income_or_pending_taxes]);

  useEffect(() => {
    if (!has_dividends) {
      void formik.setFieldValue('dividends', []);
    } else if (has_dividends) {
      const dividendsArray = formik.values.dividends || [];
      if (!Array.isArray(dividendsArray) || dividendsArray.length === 0) {
        void formik.setFieldValue('dividends', [{ ...emptyDividend }]);
      }
    }
  }, [has_dividends]);

  // Helper function to add a new dividend
  const addDividend = () => {
    const dividendsArray = formik.values.dividends || [];
    if (Array.isArray(dividendsArray)) {
      void formik.setFieldValue('dividends', [
        ...dividendsArray,
        { ...emptyDividend },
      ]);
    } else {
      void formik.setFieldValue('dividends', [{ ...emptyDividend }]);
    }
  };

  // Helper function to remove a dividend at a specific index
  const removeDividend = (index: number) => {
    const dividendsArray = formik.values.dividends || [];
    if (Array.isArray(dividendsArray)) {
      const currentDividends = [...dividendsArray];
      currentDividends.splice(index, 1);
      void formik.setFieldValue('dividends', currentDividends);
    }
  };

  return {
    formik,
    addDividend,
    removeDividend,
    emptyDividend,
  };
}
