import ConfirmModal from '@components/Modal/ConfirmModal';
import DeductionDetails from '@components/DeductionDetails/DeductionDetails';

interface PersonalDeductionModalProps {
  openPersonalDeductionModal: boolean;
  toggleOpenPersonalDeductionModal: () => void;
  periodDate: string;
}

export default function PersonalDeductionModal({
  openPersonalDeductionModal,
  toggleOpenPersonalDeductionModal,
  periodDate,
}: PersonalDeductionModalProps) {
  return (
    <ConfirmModal
      open={openPersonalDeductionModal}
      title={`Resumen de deducciones personales - ${periodDate}`}
      onClose={toggleOpenPersonalDeductionModal}
      centered
      size="lg"
      withoutAcceptBtn
    >
      <DeductionDetails withSidebar={false} periodDate={periodDate} />
    </ConfirmModal>
  );
}
