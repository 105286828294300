import React, { useContext } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import LetterAvatar from 'react-avatar';
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import {
  Flex,
  Text,
  Button,
  useMediaQuery,
  config,
  Chip,
  styled,
} from '@konta/ui';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import {
  PhoneIcon,
  BankCard1Icon,
  BookmarkIcon,
  LockIcon,
  Logout2Icon,
  SettingsIcon,
  User1Icon,
  NotificationBoxLineIcon,
} from '@konta/icons';
import { clickOnMobileMenu, logoutUser } from '@redux/actions';
import useFeature from '@hooks/useFeature';
import {
  TopNavHelpCenterSearch,
  OldTopNavHelpCenterSearch,
  LinkList,
} from '@components';
import { useWindowSize } from '@hooks';
import { getModuleTitle, getModuleTabsWithLinks } from '@util';
import IntlMessages from '@util/IntlMessages';
import {
  CALENDLY_MODAL,
  COMMAND_MENU,
  NOTIFICATION_CENTER,
} from '@constants/featureFlags';
import { IS_LOCAL } from '@constants/enviroments';
import ChangelogWidgetButton from '@components/ChangelogWidget';
import useCurrentProcessesQuery from '@hooks/useCurrentProcessesQuery';
import { ScheduleCallContext } from '@providers/ScheduleCallProvider';
import useProcess from '@hooks/useProcess';
import { PROCESSES_HOME_ROUTE } from '@constants/process';
import { ProcessType } from 'types/entities.ts';

const StyledRoot = styled(Flex, {
  padding: '0px',
  height: '67px',
  borderBottom: '1px solid rgb(239, 241, 244)',
  minHeight: '67px',
});

const featureFlagSource = {
  source: 'TopNav',
};

function TopNav({ taxable_entity, user, menuHiddenBreakpoint }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProcesses } = useCurrentProcessesQuery();
  const { activeStepRoute, process } = useProcess(
    ProcessType.OnboardingOnboardingProcess,
  );

  const isDesktop = useMediaQuery(config.media.sm);
  const isCompletedOnboardingOnboardingProcess = process?.completed;
  const showOnboardingChip =
    !!process &&
    currentProcesses?.length > 1 &&
    !isCompletedOnboardingOnboardingProcess &&
    location.pathname !== PROCESSES_HOME_ROUTE;
  const isRejected = taxable_entity?.onboarding_detail?.is_rejected_user;
  const { pathname } = useLocation();
  const { toggleCalendly } = useContext(ScheduleCallContext);
  const [calendlyModalEnabled] = useFeature(CALENDLY_MODAL, featureFlagSource);
  const [isNotificationCenterEnabled] = useFeature(
    NOTIFICATION_CENTER,
    featureFlagSource,
  );
  const [isCommandMenuEnabled] = useFeature(COMMAND_MENU, featureFlagSource);
  const handleLogout = () => {
    dispatch(logoutUser(navigate));
  };
  const size = useWindowSize();

  const tabs = getModuleTabsWithLinks(location, isRejected);
  const avatar_name = taxable_entity?.legal_name
    ? taxable_entity.legal_name
    : user.first_name;
  const moduleNameFromPath = getModuleTitle(location);
  const isRejectedUserOrNotActive =
    !taxable_entity?.onboarding_detail?.is_rejected_user &&
    taxable_entity.status !== 'demo';
  const isMobileSize = size.width < menuHiddenBreakpoint;
  const isOnlyTitle = isMobileSize ? true : !tabs;

  return (
    <StyledRoot justify="between">
      <div
        className={`navbar-title w-50 ${
          isOnlyTitle ? 'd-flex align-items-center' : 'navbar-with-tabs'
        }`}
      >
        {moduleNameFromPath && (
          <Flex
            css={{
              mt: '8px',
              mb: '4px',
              flexDirection: isDesktop ? 'row' : 'column',
              gap: 4,
            }}
          >
            <Text bold>
              <IntlMessages id={`menu.${moduleNameFromPath}`} />
            </Text>
            {showOnboardingChip && (
              <Chip
                color="primary200"
                variant="contained"
                size={isDesktop ? 'm' : 'xs'}
                data-testid="OnboardingProcessTopNav"
                label={
                  <Text overflowEllipsis>Continua tu apertura de cuenta</Text>
                }
                iconAction={<ArrowRightIcon />}
                onClick={() =>
                  navigate(
                    isCompletedOnboardingOnboardingProcess
                      ? PROCESSES_HOME_ROUTE
                      : activeStepRoute,
                  )
                }
                css={{ cursor: 'pointer' }}
              />
            )}
          </Flex>
        )}
        {!isOnlyTitle && (
          <Nav className="nav-tabs-topnav">
            <LinkList
              base=""
              current={pathname}
              tabs={tabs.labels}
              links={tabs.links}
              videoHelper={false}
              videoID="K5b54p7VQmA"
            />
          </Nav>
        )}
      </div>
      <div className="navbar-left">
        {isCommandMenuEnabled ? (
          <TopNavHelpCenterSearch placeholder="Buscar..." />
        ) : (
          <OldTopNavHelpCenterSearch
            mobile
            className="topnav-search "
            placeholder="Buscar..."
          />
        )}
        <ChangelogWidgetButton />
        {((!isMobileSize && isRejectedUserOrNotActive) || IS_LOCAL) &&
          calendlyModalEnabled &&
          (isDesktop ? (
            <Button
              variant="outlined"
              size="s"
              color="gray"
              onClick={toggleCalendly}
              leftIcon={<PhoneIcon />}
            >
              Agendar llamada
            </Button>
          ) : (
            <Button
              variant="outlined"
              icon
              size="xs"
              color="gray"
              onClick={toggleCalendly}
            >
              <PhoneIcon />
            </Button>
          ))}

        <div className="sidebar-header-profile-container">
          <div className="sidebar-header-profile">
            <UncontrolledDropdown>
              <DropdownToggle caret className="sidebar-header-profile-button">
                <span className="sidebar-header-profile-image-container">
                  {taxable_entity.logo_url ? (
                    <img
                      src={taxable_entity.logo_url}
                      className="sidebar-header-profile-image"
                      alt="konta-logo"
                    />
                  ) : (
                    <LetterAvatar
                      maxInitials={2}
                      name={avatar_name}
                      size="22"
                      round
                    />
                  )}
                </span>
              </DropdownToggle>
              <DropdownMenu className="sidebar-header-dropdown-menu">
                <DropdownItem header className="sidebar-header-dropdown-item">
                  <div className="sidebar-header-dropdown-item-text">
                    {taxable_entity.legal_name
                      ? taxable_entity.legal_name
                      : user.first_name}
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <NavLink to="/app/settings">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <SettingsIcon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Configuración
                    </div>
                  </DropdownItem>
                </NavLink>
                <NavLink to="/app/settings/plan">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <BookmarkIcon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Mi plan
                    </div>
                  </DropdownItem>
                </NavLink>
                <NavLink to="/app/settings/methods">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <BankCard1Icon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Métodos de pago
                    </div>
                  </DropdownItem>
                </NavLink>
                <NavLink to="/app/settings/credentials">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <LockIcon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Credenciales SAT
                    </div>
                  </DropdownItem>
                </NavLink>
                <NavLink to="/app/settings/security">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <LockIcon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Seguridad
                    </div>
                  </DropdownItem>
                </NavLink>
                <NavLink to="/app/settings/brand">
                  <DropdownItem className="sidebar-header-dropdown-item">
                    <User1Icon className="sidebar-header-dropdown-item-icon" />
                    <div className="sidebar-header-dropdown-item-text">
                      Imagen de marca
                    </div>
                  </DropdownItem>
                </NavLink>
                {isNotificationCenterEnabled && (
                  <NavLink to="/app/settings/notifications-center">
                    <DropdownItem className="sidebar-header-dropdown-item">
                      <NotificationBoxLineIcon className="sidebar-header-dropdown-item-icon" />
                      <div className="sidebar-header-dropdown-item-text">
                        Centro de notificaciones
                      </div>
                    </DropdownItem>
                  </NavLink>
                )}
                <DropdownItem divider />
                <DropdownItem
                  className="sidebar-header-dropdown-item"
                  onClick={handleLogout}
                >
                  <Logout2Icon className="sidebar-header-dropdown-item-icon" />
                  <div className="sidebar-header-dropdown-item-text">
                    Cerrar sesión
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </StyledRoot>
  );
}

const mapStateToProps = ({ menu, authUser, taxableEntity }) => {
  const { subHiddenBreakpoint, menuHiddenBreakpoint } = menu;
  const { taxable_entity } = taxableEntity;
  const { user } = authUser;
  return { subHiddenBreakpoint, menuHiddenBreakpoint, taxable_entity, user };
};
export default connect(mapStateToProps, { clickOnMobileMenu, logoutUser })(
  TopNav,
);
