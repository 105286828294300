import dayjs from 'dayjs';
import { PaginationParams } from 'types/pagination';
import { ColumnFiltersState } from '@tanstack/react-table';

const format = 'YYYY-MM-DD';
export default function getRansackFilters(
  filters: ColumnFiltersState,
  type: 'incomes' | 'expenses',
  periodDate?: string,
  selectedCategory?: string | null,
): PaginationParams {
  const defaultFilters: PaginationParams = {
    'q[status_eq]': 1,
  };

  const ransackFilters: PaginationParams = { ...defaultFilters };

  filters.forEach((filter) => {
    if (selectedCategory) {
      const diffetentCategory = filter.value !== selectedCategory;
      if (diffetentCategory) {
        const cfdiUseKeys = {
          health: ['D01', 'D02'],
          funeral_expenses: ['D03'],
          donations: ['D04'],
          mortgage_interest: ['D05'],
          retirement_fund: ['D06', 'D09'],
          insurance: ['D07'],
          school_transportation: ['D08'],
          education: ['D10'],
        }[selectedCategory];

        const educationLevel = [
          'Preescolar',
          'Primaria',
          'Secundaria',
          'Bachillerato',
          'Profesional técnico',
        ];

        ransackFilters['q[cfdi_use_key_in]'] = cfdiUseKeys;

        const currentDate = periodDate ? dayjs(periodDate) : dayjs();
        const startDate = currentDate.startOf('year').format(format);
        const endDate = currentDate.endOf('year').format(format);
        ransackFilters['q[date_issued_gteq_all]'] = startDate;
        ransackFilters['q[date_issued_lteq_all]'] = endDate;
        if (selectedCategory === 'education') {
          ransackFilters['q[education_level_in]'] = educationLevel;
        }
        ransackFilters['q[is_emited_false]'] = 1;
      }
    }

    if (
      !selectedCategory &&
      filter.id === 'dateIssued' &&
      Array.isArray(filter.value)
    ) {
      const [startDate, endDate] = filter.value.map((dateString: string) =>
        dayjs(dateString).isValid() ? dayjs(dateString).format(format) : null,
      );
      const areSameDates = periodDate === startDate;

      ransackFilters['q[groupings][][g][0][is_emited_true]'] = 1;
      ransackFilters['q[groupings][][g][0][invoice_type_in]'] = [0, 3];

      ransackFilters['q[groupings][][g][1][is_emited_false]'] = 1;
      ransackFilters['q[groupings][][g][1][invoice_type_in]'] = [3];

      ransackFilters['q[groupings][][g][2][is_emited_true]'] = 1;
      ransackFilters['q[groupings][][g][2][invoice_type_in]'] = [1];

      ransackFilters['q[groupings][][m]'] = 'or';

      if (startDate && areSameDates)
        ransackFilters['q[groupings][][g][0][date_issued_gteq_all]'] =
          startDate;
      ransackFilters['q[groupings][][g][1][date_issued_gteq_all]'] = startDate;
      ransackFilters['q[groupings][][g][2][date_issued_gteq_all]'] = startDate;

      if (endDate && areSameDates)
        ransackFilters['q[groupings][][g][0][date_issued_lteq_all]'] = endDate;
      ransackFilters['q[groupings][][g][1][date_issued_lteq_all]'] = endDate;
      ransackFilters['q[groupings][][g][2][date_issued_lteq_all]'] = endDate;
    }
  });

  return ransackFilters;
}
