import React, { useState } from 'react';
import { Button, Flex } from '@konta/ui';
import { useMutation } from 'react-query';
import { ProcessType, PaymentMethod, AnnualStep } from 'types/entities';
import { usePaymentMethods } from '@hooks';
import postPayOrder from '@api/postPayOrder';
import NextStepButton from '@components/NextStepButton';
import ProcessStepLayout from '@components/ProcessStepLayout';
import { NotificationManager } from '@components';
import { PaymentCards } from '@containers';
import useProcess from '@hooks/useProcess';
import ProcessStepTitle from '@components/ProcessStepTitle';
import ProcessStepDescription from '@components/ProcessStepDescription';
import getErrorMessage from '@util/getErrorMessage';
import { useToggle } from 'usehooks-ts';
import { ConfirmModal } from '@components/Modal';
import AnnualCheckout from './AnnualCheckout';

interface SelectedPaymentMethod {
  loading: boolean;
  ui: {
    selected_card: PaymentMethod | null;
  };
}

const onError = (error: string) => {
  const message = getErrorMessage(error) as string;
  NotificationManager.error(message, 'Error', 10000);
};

export default function ChargesAnnual() {
  const { ui, loading } = usePaymentMethods() as SelectedPaymentMethod;
  const { steps, next, activeStep, updatingProcess, loadingCurrentProcesses } =
    useProcess(ProcessType.AnnualDeclarationProcess);
  const [payload, setPayload] = useState<string>();
  const process = ProcessType.AnnualDeclarationProcess;
  const [openDeclineModal, toggleDeclineModal] = useToggle(false);
  const orderId = (steps as AnnualStep).welcome?.answers.order_id;

  const payOrder = useMutation(postPayOrder, {
    onSuccess: () => {
      setPayload('paid');
      NotificationManager.success(
        'Se ha realizado el pago de tu orden de forma exitosa ',
        'Orden pagada con éxito',
        3000,
      );
    },
    onError,
  });

  const goToRejectionReasonStep = async () => {
    try {
      await next({
        steps_attributes: [{ id: activeStep?.id, answers: 'skip' }],
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e) as string;
      NotificationManager.error(errorMessage, 'Cobro de declaración anual');
    }
  };

  const isLoading =
    payOrder.isLoading || loading || updatingProcess || loadingCurrentProcesses;

  return (
    <ProcessStepLayout
      currentProcessType={process}
      title="Declaración anual"
      titleChip="En curso"
      fullContent
      content={
        <>
          <ConfirmModal
            loading={isLoading}
            title="Declaración anual"
            open={openDeclineModal}
            onAccept={() => {
              void goToRejectionReasonStep();
            }}
            onCancel={toggleDeclineModal}
            onClose={toggleDeclineModal}
          >
            ¡Estás a punto de rechazar el servicio de declaración anual!
          </ConfirmModal>
          <Flex direction="column" gap={12}>
            <ProcessStepTitle>Paga tu declaración anual</ProcessStepTitle>
            <ProcessStepDescription>
              Para continuar, es necesario realizar el pago correspondiente. El
              costo de tu declaración anual es equivalente a un mes de servicio
              de tu suscripción mensual. Una vez completado el pago, nuestro
              equipo fiscal procesará tu declaración de impuestos y te
              mostraremos los resultados en cuanto sea posible.
            </ProcessStepDescription>
          </Flex>
          <Flex direction="column">
            <PaymentCards filter />
          </Flex>
          <Flex direction="column">
            <AnnualCheckout />
          </Flex>
          <Flex gap={12} justify="start">
            <Button
              color="gray"
              variant="outlined"
              loading={isLoading}
              onClick={toggleDeclineModal}
            >
              No estoy interesado en este beneficio
            </Button>
            <NextStepButton
              currentProcessType={process}
              data-cy="chargesAnnualNextBtn"
              loading={isLoading}
              disabled={!ui.selected_card}
              text="Pagar"
              payload={payload}
              onClick={() => {
                if (ui.selected_card) {
                  payOrder.mutate({
                    orderId,
                    paymentMethodId: ui.selected_card.id,
                  });
                }
              }}
            />
          </Flex>
        </>
      }
    />
  );
}
